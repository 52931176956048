import { CommutifiIconDefinition } from '../types'

export const faParkingRegular: CommutifiIconDefinition = {
  prefix: 'far',
  iconName: 'parking',
  icon: [
    512,
    512,
    [],
    '16',
    'M 0.339844 41.300781 C 0.339844 18.679688 18.679688 0.339844 41.300781 0.339844 L 454.3125 0.339844 C 476.933594 0.339844 495.273438 18.679688 495.273438 41.300781 L 495.273438 454.3125 C 495.273438 476.9375 476.933594 495.273438 454.3125 495.273438 L 41.300781 495.273438 C 18.679688 495.273438 0.339844 476.9375 0.339844 454.3125 Z M 74.578125 70.316406 C 74.578125 59.003906 83.75 49.835938 95.058594 49.835938 L 425.300781 49.835938 C 436.609375 49.835938 445.78125 59.003906 445.78125 70.316406 L 445.78125 425.300781 C 445.78125 436.609375 436.609375 445.78125 425.300781 445.78125 L 95.058594 445.78125 C 83.75 445.78125 74.578125 436.609375 74.578125 425.300781 Z M 148.820312 116.652344 L 148.820312 377.578125 L 208.921875 377.578125 L 208.921875 292.191406 L 263.160156 292.191406 C 284.171875 292.191406 302.617188 288.527344 318.496094 281.195312 C 334.378906 273.867188 346.59375 263.542969 355.144531 250.230469 C 363.695312 236.914062 367.96875 221.339844 367.96875 203.503906 C 367.96875 185.914062 363.695312 170.582031 355.144531 157.511719 C 346.59375 144.441406 334.4375 134.363281 318.679688 127.277344 C 302.921875 120.191406 284.535156 116.652344 263.527344 116.652344 Z M 258.394531 246.015625 L 208.921875 246.015625 L 208.921875 162.828125 L 258.394531 162.828125 C 273.300781 162.828125 285.148438 166.550781 293.941406 174.003906 C 302.738281 181.457031 307.136719 191.535156 307.136719 204.238281 C 307.136719 216.941406 302.738281 227.082031 293.941406 234.65625 C 285.148438 242.230469 273.300781 246.015625 258.394531 246.015625 Z M 258.394531 246.015625 '
  ]
}

export const faParkingSolid: CommutifiIconDefinition = {
  prefix: 'fas',
  iconName: 'parking',
  icon: [
    512,
    512,
    [],
    '31',
    'M 358.75 0 L 51.25 0 C 22.945312 0 0 22.921875 0 51.199219 L 0 460.800781 C 0 489.078125 22.945312 512 51.25 512 L 358.75 512 C 387.054688 512 410 489.078125 410 460.800781 L 410 51.199219 C 410 22.921875 387.054688 0 358.75 0 Z M 307.5 230.398438 C 307.5 272.816406 273.082031 307.199219 230.625 307.199219 L 153.75 307.199219 L 153.75 371.199219 C 153.75 378.269531 148.011719 384 140.9375 384 L 115.3125 384 C 108.238281 384 102.5 378.269531 102.5 371.199219 L 102.5 140.800781 C 102.5 133.730469 108.238281 128 115.3125 128 L 230.625 128 C 273.082031 128 307.5 162.382812 307.5 204.800781 Z M 153.75 179.199219 L 230.625 179.199219 C 244.777344 179.199219 256.25 190.660156 256.25 204.800781 L 256.25 230.398438 C 256.25 244.539062 244.777344 256 230.625 256 L 153.75 256 Z M 153.75 179.199219 '
  ]
}
