import { Button as AntButton } from 'antd'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faPen, faTrashAlt, faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons'
import { faLongArrowLeft, faLongArrowRight } from '@commutifi-fe/custom-icons/regular'
import { faEye } from '@commutifi-fe/custom-icons/solid'
import { ButtonGroupProps, ButtonHTMLType, ButtonProps, ButtonShape, ButtonSize, ButtonType } from 'antd/es/button'
import { Overwrite } from 'utility-types'
import cx from 'classnames'
import React from 'react'
import './styles.scss'
import { useLocaleReceiver } from '../locales/LocaleReceiver'
import { spacing } from '../style/constants'

const { Group } = AntButton

export type { ButtonGroupProps, ButtonHTMLType, ButtonShape, ButtonSize, ButtonType }
export enum CommutifiButtonPreset {
  Edit = 'edit',
  Delete = 'delete',
  View = 'view',
  Caret = 'caret',
  Back = 'back',
  GoTo = 'goTo',
  Add = 'add'
}

export interface AppButtonProps
  extends Overwrite<
    ButtonProps,
    {
      type?: ButtonType | 'text-secondary'
    }
  > {
  /**
   * Icon to display after the button text.
   */
  suffixIcon?: React.ReactNode
  /**
   * Shortcut notation to use pre-built specific buttons
   * like CaretButton, DeleteButton, EditButton, etc.
   */
  preset?: `${CommutifiButtonPreset}`
  /**
   * Use this props ONLY on text or link buttons to make the button
   * height and width fit to the content instead of having a defined height and padding.
   */
  fitToContent?: boolean
  underline?: boolean
}

export interface ButtonComponent
  extends React.ForwardRefExoticComponent<AppButtonProps & React.RefAttributes<HTMLButtonElement>> {
  Group: typeof Group
  Classes: {
    floatOnMobile: string
  }
}

const ButtonClass = 'app-button'

/**
 * This class is a wrap around [antd Button](https://ant.design/components/button/) component extending some of
 * the properties to follow our styling guidelines.
 *
 * Please refer to antd documentation for more details.
 *
 * IMPORTANT Style guide to antd property mapping (antd - commutifi style):
 *      | antd |    Figma styleguide |
 *    type
 *      - primary : Primary
 *      - primary + ghost=true  : Secondary
 *      - default : Tertiary
 *      - text : Same than antd but without padding
 *      - link : Same than antd but without padding
 *      - secondary : Ghost
 *    size
 *      - large : onboarding flow + future cases ?
 *      - middle (new default value) : medium
 *      - small : compact
 * ------
 *https://typescript-eslint.io/rules/no-shadow
 * @example Use icon prop to display an icon BEFORE the button text
 * @example Use children to have the flexibility to put the icon AFTER the text
 *
 * @example Use type='link' to use button links (You might need to adjust padding)
 */
export const Button = React.forwardRef<HTMLButtonElement, AppButtonProps>(
  ({ className, children, preset, icon, suffixIcon, fitToContent, underline, type, ...buttonProps }, ref) => {
    const getVariant = (preset?: `${CommutifiButtonPreset}`) => {
      switch (preset) {
        case CommutifiButtonPreset.Back:
          return BackButton
        case CommutifiButtonPreset.Caret:
          return CaretButton
        case CommutifiButtonPreset.Delete:
          return DeleteButton
        case CommutifiButtonPreset.Edit:
          return EditButton
        case CommutifiButtonPreset.GoTo:
          return GoToButton
        case CommutifiButtonPreset.View:
          return ViewButton
        case CommutifiButtonPreset.Add:
          return PlusButton
        default:
          return AntButton
      }
    }

    const ButtonComponent = getVariant(preset)

    return (
      <ButtonComponent
        ref={ref}
        size="middle"
        icon={
          React.isValidElement(icon) ? (
            <span className="anticon ant-btn-icon" style={{ display: 'inline-flex' }}>
              {icon}
            </span>
          ) : (
            icon
          )
        }
        {...buttonProps}
        type={type === 'text-secondary' ? 'text' : type}
        className={cx(
          ButtonClass,
          underline && `${ButtonClass}--underline`,
          fitToContent && `${ButtonClass}--fit-to-content`,
          type === 'text-secondary' && `${ButtonClass}--text-secondary`,
          className
        )}
      >
        {children}
        {suffixIcon ? <span className="u-margin-left--small">{suffixIcon}</span> : null}
      </ButtonComponent>
    )
  }
) as ButtonComponent

Button.displayName = 'Button'
Button.Group = Group
Button.Classes = {
  /**
   * To make the button 'float' on bottom of the screen
   * when it's mobile display.
   */
  floatOnMobile: `${ButtonClass}--float`
}
export default Button

/**
 * Following components are Button presets that you can use with the common Button
 * component passing preset property.
 * NOTE: They are in the same file to avoid circular dependencies
 */

export const LinkButton = React.forwardRef<HTMLButtonElement, AppButtonProps>(({ className, style, ...props }, ref) => (
  <Button ref={ref} size="small" type="link" className={className} style={{ padding: 0, ...style }} {...props} />
))
LinkButton.displayName = 'LinkButton'

interface EditButtonProps extends ButtonProps {
  onEdit?: (e: React.MouseEvent) => void
  className?: string
  text?: React.ReactNode
  style?: React.CSSProperties
}

export const EditButton = React.forwardRef<HTMLButtonElement, EditButtonProps>(
  ({ onEdit, onClick, className, text, style, type, ...btnProps }, ref) => {
    const intl = useLocaleReceiver('Button')
    return (
      <Button
        ref={ref}
        onClick={onEdit || onClick}
        type={type || 'link'}
        style={style}
        className={cx('app-edit-button', className)}
        data-testid="edit-button"
        {...btnProps}
        icon={<Icon icon={faPen} size="sm" />}
      >
        <span>{text || intl.formatMessage({ id: 'editVariantText' })}</span>
      </Button>
    )
  }
)
EditButton.displayName = 'EditButton'

interface DeleteButtonProps extends AppButtonProps {
  // Support legacy property to handle button click
  onDelete?: React.MouseEventHandler
  showIcon?: boolean
}

export const DeleteButton = React.forwardRef<HTMLButtonElement, DeleteButtonProps>(
  ({ onDelete, onClick, className, children, showIcon = true, ...btnProps }, ref) => {
    const intl = useLocaleReceiver('Button')
    return (
      <Button
        ref={ref}
        ghost={btnProps.type !== 'link' && btnProps.type !== 'text'}
        onClick={onDelete || onClick}
        className={cx('u-font-weight-semi', className)}
        {...btnProps}
        icon={showIcon ? <Icon icon={faTrashAlt} /> : undefined}
      >
        {children || intl.formatMessage({ id: 'deleteVariantText' })}
      </Button>
    )
  }
)
DeleteButton.displayName = 'DeleteButton'

interface TextButtonProps {
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
  icon?: React.ReactNode
  text: React.ReactNode
  suffix?: React.ReactNode
  className?: string
  style?: React.CSSProperties
}

/**
 * Legacy, only used in ViewButton (button with eye icon)
 */
export const TextButton = React.forwardRef<HTMLButtonElement, TextButtonProps>(
  ({ onClick, icon, suffix, text, className, style }, ref) => {
    const handleOnClick: AppButtonProps['onClick'] = (e) => {
      e.preventDefault()
      if (onClick) {
        onClick(e as React.MouseEvent<HTMLAnchorElement>)
      }
    }
    return (
      <Button ref={ref} onClick={handleOnClick} type="link" style={{ padding: 0, ...style }} className={className}>
        {icon ? <span className="u-margin-right--tiny">{icon}</span> : null}
        {text}
        {suffix ? <span className="u-margin-left--tiny">{suffix}</span> : null}
      </Button>
    )
  }
)
TextButton.displayName = 'TextButton'

interface ViewButtonProps {
  onClick?: AppButtonProps['onClick']
}

/**
 * Legacy, only used in detailed view of organization dashboard Table views.
 * We should use our app UI components instead
 *
 * Text: View
 * Icon: Eye
 * @defaultValue type='text' (Dark grey - neutral-500)
 */
export const ViewButton = React.forwardRef<HTMLButtonElement, ViewButtonProps>(({ onClick }, ref) => {
  const intl = useLocaleReceiver('Button')

  return (
    <TextButton
      ref={ref}
      onClick={onClick}
      icon={<Icon icon={faEye} />}
      text={intl.formatMessage({ id: 'viewVariantText' })}
    />
  )
})
ViewButton.displayName = 'ViewButton'

/**
 * Icon: [PREFIX] long arrow lest
 * @defaultValue type='link' (Primary color)
 */
export const BackButton = React.forwardRef<HTMLButtonElement, AppButtonProps>(
  ({ children, className, ...buttonProps }, ref) => (
    <Button
      ref={ref}
      type="link"
      className={cx('u-font-weight-semi', 'u-color--neutral-600', className)}
      {...buttonProps}
      icon={<Icon icon={faLongArrowLeft} />}
    >
      {children}
    </Button>
  )
)
BackButton.displayName = 'BackButton'

/**
 * Icon: [SUFFIX] caret down
 */
export const CaretButton = React.forwardRef<HTMLButtonElement, AppButtonProps>(
  ({ loading, children, ...buttonProps }, ref) => (
    <Button ref={ref} loading={loading} {...buttonProps}>
      {children}
      <Icon icon={faCaretDown} style={{ marginLeft: spacing.small }} />
    </Button>
  )
)
CaretButton.displayName = 'CaretButton'

/**
 * Icon: [SUFFIX] long arrow right
 * @defaultValue type='link' (Primary color)
 */
export const GoToButton = React.forwardRef<HTMLButtonElement, AppButtonProps>(({ children, ...buttonProps }, ref) => (
  <Button ref={ref} type="link" size="small" style={{ paddingLeft: 0 }} {...buttonProps}>
    <span className="u-font-weight-semi">
      {children}
      <Icon icon={faLongArrowRight} style={{ marginLeft: spacing.small }} />
    </span>
  </Button>
))
GoToButton.displayName = 'GoToButton'

/**
 * Icon: [PREFIX] plus circle
 * @defaultValue type='link' (Primary color)
 */
export const PlusButton = React.forwardRef<HTMLButtonElement, AppButtonProps>(
  ({ children, style, ...buttonProps }, ref) => (
    <Button
      ref={ref}
      {...buttonProps}
      size="small"
      type="link"
      style={{ ...style, padding: 0 }}
      icon={<Icon icon={faPlusCircle} />}
    >
      {children}
    </Button>
  )
)
PlusButton.displayName = 'PlusButton'
