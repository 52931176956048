import { CommutifiIconDefinition } from '../types'

export const faEye: CommutifiIconDefinition = {
  prefix: 'fas',
  iconName: 'commutifi-eye',
  icon: [
    512,
    512,
    [],
    '42',
    'M 242.898438 78.804688 C 221.753906 80.082031 207.941406 82.117188 189.777344 86.5625 C 130.175781 101.273438 77.101562 136.46875 39.6875 186.039062 C 29.988281 198.90625 21.429688 212.953125 13.527344 228.992188 C 2.886719 250.558594 2.035156 254.910156 6.386719 265.886719 C 20.007812 299.992188 44.132812 334.804688 71.851562 360.347656 C 114.472656 399.65625 166.597656 423.6875 223.789062 430.402344 C 284.191406 437.546875 344.785156 424.679688 396.15625 393.933594 C 445.492188 364.320312 485.132812 318.203125 506.464844 265.367188 C 508.214844 261.015625 509.351562 253.304688 508.785156 249.332031 C 508.214844 245.074219 498.566406 224.6875 490.949219 211.679688 C 483.429688 198.765625 474.488281 186.085938 464.460938 174.023438 C 458.027344 166.265625 439.816406 148.101562 432.105469 141.71875 C 404.386719 118.777344 375.910156 102.882812 342.707031 92.003906 C 323.359375 85.617188 304.296875 81.691406 283.339844 79.703125 C 276.007812 78.996094 249.332031 78.425781 242.898438 78.804688 Z M 263.472656 180.457031 C 287.738281 182.113281 311.863281 198.667969 323.5 221.660156 C 326.386719 227.335938 329.269531 235.945312 330.453125 242.425781 C 331.777344 249.472656 331.683594 262.859375 330.21875 269.625 C 327.09375 284.429688 320.613281 296.917969 310.777344 307.179688 C 295.96875 322.601562 277.332031 330.644531 256.332031 330.691406 C 246.160156 330.691406 237.550781 329.082031 228.707031 325.582031 C 194.742188 312.195312 175.066406 275.960938 182.398438 240.34375 C 189.210938 207.277344 217.402344 182.585938 250.9375 180.269531 C 253.539062 180.078125 256.519531 179.984375 257.5625 180.03125 C 258.601562 180.128906 261.25 180.316406 263.472656 180.457031 Z M 263.472656 180.457031 '
  ]
}
