import { flatten } from 'lodash'
import { Route } from 'shared/interfaces'

/**
 * If the user autocompleted his commute with an external service (like google) we need to flatten those
 * transit legs into our route existing legs. We also want to remove categories if modes or defined before sending
 * the data to the backend
 * @param route - Standard commutifi route (see Route interface)
 */
export const formatRouteTransitLegs = (route: Route) => ({
  ...route,
  legs: flatten(
    // For each leg
    route.legs?.map((leg) => ({
      ...leg,
      // Check if the have a transit "route" associated with it, if it does, get the transit legs and
      // format them
      ...(leg.transit?.legs
        ? {
            preferredShape: leg.transit.legs.map((transitLeg) => ({
              mode: transitLeg.mode,
              transitLine: transitLeg.name
            }))
          }
        : {}),
      category: leg.mode ? undefined : leg.category
    }))
  )
})
