import { CommutifiIconDefinition } from '../types'

export const faStars: CommutifiIconDefinition = {
  prefix: 'fas',
  iconName: 'stars',
  icon: [
    512,
    512,
    [],
    '26',
    'M 5.15625 201.207031 C 0.832031 198.054688 -1.019531 192.503906 0.554688 187.382812 L 2.597656 180.984375 C 4.09375 175.929688 8.59375 172.347656 13.851562 172.027344 L 177.277344 158.972656 L 239.9375 8.222656 C 242.039062 2.941406 247.316406 -0.371094 252.980469 0.03125 L 259.632812 0.03125 C 264.972656 -0.0820312 269.796875 3.207031 271.648438 8.222656 L 334.566406 158.972656 L 497.992188 172.027344 C 503.25 172.347656 507.75 175.929688 509.246094 180.984375 L 511.292969 187.382812 C 513.03125 192.339844 511.5 197.855469 507.453125 201.207031 L 384.179688 307.164062 L 422.03125 466.109375 C 423.28125 471.195312 421.367188 476.535156 417.171875 479.671875 L 409.757812 483.765625 C 405.429688 486.695312 399.761719 486.695312 395.433594 483.765625 L 256.304688 399.308594 L 116.410156 484.535156 C 112.082031 487.460938 106.414062 487.460938 102.085938 484.535156 L 96.460938 480.695312 C 92.265625 477.558594 90.355469 472.21875 91.601562 467.132812 L 128.429688 307.164062 Z M 5.15625 201.207031 '
  ]
}
