import { Primitive, Required } from 'utility-types'
import { Account, GetResponse, Location, OfficeWithoutBuilding, RequestParams } from '@commutifi-fe/interfaces'
import { AttributeProps } from '@commutifi/models/Attributes'
import { BookingProps, BookingStatus } from '@commutifi/models/Bookings'
import { BuildingProps } from '@commutifi/models/Buildings'
import { EnterpriseDepositProps } from '@commutifi/models/EnterpriseDeposits'
import { EnterpriseNotificationTemplatesProps } from '@commutifi/models/EnterpriseNotificationTemplates'
import { EnterpriseKind, EnterpriseProps, ShippingRates } from '@commutifi/models/Enterprises'
import { FeatureFlagResponse } from '@commutifi/models/FeatureFlag'
import { InventoryProps } from '@commutifi/models/Inventory'
import type { MerchantProps } from '@commutifi/models/Merchant'
import { OfficeProps } from '@commutifi/models/Offices'
import { PlanProps } from '@commutifi/models/Plan'
import { CommutifiError, IOrganization, OfficeGeocodeValue } from 'shared/interfaces'
import { captureIsPlanValid } from '../../utils/global'
import { JSON_HEADER, apiCall, dashboardApiServer } from '../../index'
import { PlanPermissionProps } from '../commuterPlans'
import { Submission } from '../compliance/api'
import { InvoiceWithRelations } from '../invoices'
import { PaymentMethod, PostPaymentMethodBody } from '../paymentMethods'
import { AccountWallet, GroupWalletWithMembers, Wallet } from '../wallets'
import endpoints from './endpoints'

export interface FundingInstructions {
  type: 'aba' | 'sort_code' | 'iban'
  accountNumber?: string
  bankName?: string
  routingNumber?: string
  accountHolderName?: string
  sortCode?: string
  bic?: string
  country?: string
  iban?: string
  reference?: string
  swift?: string
}

const apiDashboardServer = apiCall(dashboardApiServer)

// Enterprise accounts: Accounts objects linked to an enterprise
// -----------------------------------------------------------
export const fetchAccounts = (
  kind: EnterpriseKind,
  entityId: string,
  query: Record<string, any>
): Promise<GetResponse<Account>> =>
  // Use of kind is still needed here as we fetch accounts using account.enterpriseId and account.organizationId
  apiDashboardServer(
    `/${kind === EnterpriseKind.organization ? 'organizations' : 'enterprises'}/${entityId}/accounts`,
    {
      method: 'get',
      queryParams: query
    }
  )

export interface ManagerAccount {
  name: string
  email: string
  phoneNumber?: string
  password: string
}

export interface ManagerEnterprise {
  name: string
  address?: {
    formattedAddress: string
    location: Location
  }
}

interface PostManagerQueryParams {
  enterpriseRegistrationId: string
}
export interface ManagerCreationPayload {
  account: ManagerAccount
  enterprise: ManagerEnterprise
}
type PostManagerApiFunc = (data: ManagerCreationPayload, queryParams: PostManagerQueryParams) => Promise<Account>
export const postManagerAccountEnterprise: PostManagerApiFunc = (data, queryParams) =>
  apiDashboardServer(endpoints.POST.Managers.route(), {
    method: 'post',
    data,
    queryParams
  })

export const importAccounts = (kind: EnterpriseKind, entityId: string) => (form: Blob, queryParams: any) =>
  // Using kind here because for now accounts have org and enterprise id columns which is why we need to make
  // the distinction until we are later in merge enterprise and org phases
  apiDashboardServer(endpoints.POST.ImportAccounts.route(kind, entityId), {
    method: 'post',
    data: form,
    params: queryParams
  })

export const postEmployees =
  (kind: EnterpriseKind, entityId: string) => (data: Record<string, unknown>, queryParams: any) =>
    // Using kind here because for now accounts have org and enterprise id columns which is why we need to make
    // the distinction until we are later in merge enterprise and org phases
    apiDashboardServer(endpoints.POST.EnterpriseAccounts.route(kind, entityId), {
      method: 'post',
      headers: JSON_HEADER,
      data: JSON.stringify(data),
      params: queryParams
    })

// Enterprise finances: balances, invoices and merchants
// ---------------------------------------------
export interface EnterpriseBalanceDetails {
  currentBalance: number
  spending: number
  deposits: number
  pendingDeposits: number
  totalBalance: number
}
export const fetchEnterpriseBalances = async (
  enterpriseId: string,
  // To make sure order of params don't change in the future but there is no params supported yet
  queryParams: Record<string, never> = {}
): Promise<EnterpriseBalanceDetails> =>
  apiDashboardServer(endpoints.GET.EnterpriseBalances.route(enterpriseId), {
    method: 'get',
    queryParams
  })

export const fetchEnterpriseInvoices = (
  enterpriseId: string,
  queryParams: RequestParams
): Promise<GetResponse<InvoiceWithRelations>> =>
  apiDashboardServer(endpoints.GET.EnterpriseInvoices.route(enterpriseId), {
    method: 'get',
    queryParams
  })
export const fetchEnterpriseDeposits = (
  enterpriseId: string,
  queryParams: RequestParams
): Promise<GetResponse<EnterpriseDepositProps>> =>
  apiDashboardServer(endpoints.GET.EnterpriseDeposits.route(enterpriseId), {
    method: 'get',
    queryParams
  })
export const getEnterpriseMerchants = (
  enterpriseId: string,
  queryParams?: RequestParams
): Promise<GetResponse<MerchantProps>> =>
  apiDashboardServer(endpoints.GET.EnterpriseMerchants.route(enterpriseId), {
    method: 'get',
    queryParams
  })

// Enterprise administration: Modify existing enterprise, Sub-enterprises, managers, attributes and feature flags
// -------------------------------------------------------------------------------------------------------------
export interface PostSubEnterpriseBody extends Partial<IOrganization> {
  office?: OfficeGeocodeValue & { address: string }
  name: string
  managerEmails?: string[]
  notificationTemplate?: EnterpriseNotificationTemplatesProps
}
interface PostSubEnterpriseResponse {
  enterprise: IOrganization
  office?: OfficeProps
  managers?: {
    accountCreation: {
      accounts: Account[]
      failedAccounts: Account[]
    }
    accountInvitation: {
      accounts: Account[]
      failedAccounts: Account[]
    }
  }
  planPermissions?: PlanPermissionProps[]
}

export interface SubEnterpriseProps extends EnterpriseProps {
  accounts?: Partial<Account>[]
  managers?: Partial<Account>[]
}

export const fetchOrgEnterprises = (id: string, queryParams: RequestParams = {}) =>
  apiDashboardServer(endpoints.GET.SubEnterprises.route(id), {
    method: 'get',
    queryParams
  })

export const fetchEnterpriseChildren = (
  enterpriseId: string,
  queryParams: RequestParams & { id?: string | string[] }
): Promise<GetResponse<SubEnterpriseProps>> =>
  apiDashboardServer(endpoints.GET.EnterpriseChildren.route(enterpriseId), {
    method: 'get',
    queryParams
  })

export const postSubEnterprise = (id: string, body: PostSubEnterpriseBody): Promise<PostSubEnterpriseResponse> =>
  apiDashboardServer(endpoints.POST.SubEnterprises.route(id), {
    method: 'post',
    headers: JSON_HEADER,
    data: JSON.stringify(body)
  })

export const enterpriseAutocomplete = (queryParams: Record<string, any>) =>
  apiDashboardServer(endpoints.GET.SuggestEnterprises.route(), {
    method: 'get',
    headers: JSON_HEADER,
    queryParams
  })

export const postEnterprise = (body: Partial<EnterpriseProps>) =>
  apiDashboardServer(endpoints.POST.Enterprises.route(), {
    method: 'post',
    headers: JSON_HEADER,
    data: JSON.stringify(body)
  })

export const patchEnterprise = (enterpriseId: string, body: Partial<IOrganization>): Promise<IOrganization> =>
  apiDashboardServer(endpoints.PATCH.Enterprise.route(enterpriseId), {
    method: 'patch',
    data: body
  })

export const fetchFeatureFlags = (
  enterpriseId: string,
  queryParams?: Record<string, any>
): Promise<FeatureFlagResponse> =>
  apiDashboardServer(endpoints.GET.EnterpriseFeatureFlags.route(enterpriseId), {
    method: 'get',
    queryParams
  })

export const postManagers = (
  id: string,
  body: {
    emails: string[]
    notificationTemplate?: EnterpriseNotificationTemplatesProps
  },
  queryParams: { enterpriseId: string }
): Promise<Required<PostSubEnterpriseResponse, 'managers'>['managers']> =>
  apiDashboardServer(endpoints.POST.SubEnterpriseManager.route(id), {
    method: 'post',
    headers: JSON_HEADER,
    data: JSON.stringify(body),
    queryParams
  })
export const deleteManager = (enterpriseId: string, managerId: string): Promise<void> =>
  apiDashboardServer(endpoints.DELETE.Manager.route(enterpriseId, managerId), {
    method: 'delete'
  })

export const fetchAttributes = (
  enterpriseId: string,
  queryParams: RequestParams & Partial<Record<keyof AttributeProps, Primitive | Primitive[]>> = {}
): Promise<GetResponse<AttributeProps>> =>
  apiDashboardServer(endpoints.GET.EnterpriseAttributes.route(enterpriseId), {
    method: 'get',
    queryParams
  })

export const verifyEnterpriseHasManager = (enterpriseId: string) =>
  apiDashboardServer(endpoints.GET.VerifyHasManager.route(EnterpriseKind.enterprise, enterpriseId), {
    method: 'get',
    headers: JSON_HEADER
  })

export const verifyOrgHasManager = (orgId: string) =>
  apiDashboardServer(endpoints.GET.VerifyHasManager.route(EnterpriseKind.organization, orgId), {
    method: 'get',
    headers: JSON_HEADER
  })

// Enterprise Bookings
// -------------------
export const fetchEnterpriseBookings = (
  enterpriseId: string,
  queryParams: RequestParams & { planId?: string; status?: BookingStatus | BookingStatus[]; enterpriseId?: string }
): Promise<GetResponse<BookingProps>> =>
  apiDashboardServer(endpoints.GET.EnterpriseBookings.route(enterpriseId), {
    method: 'get',
    queryParams
  })

export const getFundingInstructions = (
  enterpriseId: string,
  queryParams?: RequestParams
): Promise<FundingInstructions> =>
  apiDashboardServer(endpoints.GET.FundingInstructions.route(enterpriseId), {
    method: 'get',
    queryParams
  })

export const getEnterpriseServiceBookingsCount = (
  enterpriseId: string,
  serviceId: string,
  queryParams: Record<string, unknown>
): Promise<{ totalCount: number; queryCount: number }> =>
  apiDashboardServer(endpoints.GET.EnterpriseServiceBookingsCount.route(enterpriseId, serviceId), {
    method: 'get',
    queryParams
  })

export const importBookings =
  (entityId: string) =>
  (form: FormData, queryParams = {}) =>
    apiDashboardServer(endpoints.POST.ImportBookings.route(entityId), {
      method: 'post',
      data: form,
      queryParams
    })

// Enterprise Wallets + Groups Wallets + Wallet enrolments (accounts_wallets)
// -----------------------------------
export const fetchEnterpriseGroupsWallets = async (
  enterpriseId: string,
  queryParams: RequestParams & { membersCount?: boolean; walletId?: string }
): Promise<GetResponse<Required<GroupWalletWithMembers, 'id'>>> =>
  apiDashboardServer(endpoints.GET.EnterpriseGroupsWallets.route(enterpriseId), {
    method: 'get',
    queryParams
  })

export const fetchEnterpriseWallets = (
  enterpriseId: string,
  queryParams: RequestParams
): Promise<GetResponse<Wallet>> =>
  apiDashboardServer(endpoints.GET.EnterpriseWallets.route(enterpriseId), {
    method: 'GET',
    queryParams
  })

export const fetchAccountsWallets = (
  enterpriseId: string,
  queryParams: RequestParams & { latestOnly?: boolean; accountId?: string } & Partial<
      Record<keyof AccountWallet, string | number | string[]>
    >
): Promise<GetResponse<Required<AccountWallet, 'id'>>> =>
  apiDashboardServer(endpoints.GET.EnterpriseWalletEnrolments.route(enterpriseId), {
    method: 'get',
    queryParams
  })

export const fetchEnterpriseShippingRates = (enterpriseId: string): Promise<ShippingRates> =>
  apiDashboardServer(endpoints.GET.ShippingRates.route(enterpriseId), {
    method: 'get'
  })

// Enterprise Plan Permissions + Plans
// -------------------------------
export enum PlanPermissionOperation {
  Create = 'create',
  Update = 'update',
  Delete = 'delete'
}
export type PostPlanPermissionBody = {
  planId: string
  enterpriseId: string
  inventoryAllocation?: number
  operation?: `${PlanPermissionOperation}`
}[]
export const postEnterprisePlanPermissions = (
  enterpriseId: string,
  data: PostPlanPermissionBody
): Promise<PlanPermissionProps> =>
  apiDashboardServer(endpoints.POST.EnterprisePlanPermissions.route(enterpriseId), {
    method: 'POST',
    data
  })

export const fetchOneEnterprisePlan = async <T extends Record<string, any> = PlanProps>(
  enterpriseId: string,
  planId: string,
  queryParams?: RequestParams
): Promise<T> => {
  const plan = await apiDashboardServer<T, Parameters<typeof fetchOneEnterprisePlan>[2]>(
    endpoints.GET.EnterprisePlan.route(enterpriseId, planId),
    {
      method: 'get',
      queryParams
    }
  )

  captureIsPlanValid(plan, { endpoint: endpoints.GET.EnterprisePlan.route(enterpriseId, planId), queryParams })
  return plan
}

export const fetchEnterprisePlans = async (
  enterpriseId: string,
  queryParams?: RequestParams & {
    owner?: boolean
    enterpriseId?: string
    id?: string | string[]
    'service.isInventoryRestricted'?: boolean
  }
): Promise<GetResponse<PlanProps>> => {
  const res = await apiDashboardServer<
    Awaited<ReturnType<typeof fetchEnterprisePlans>>,
    Parameters<typeof fetchEnterprisePlans>[1]
  >(endpoints.GET.EnterprisePlans.route(enterpriseId), {
    method: 'get',
    queryParams
  })

  res.records = res.records.filter((plan) =>
    captureIsPlanValid(plan, { endpoint: endpoints.GET.EnterprisePlans.route(enterpriseId), queryParams })
  )
  return res
}

export const fetchEnterprisePlanServiceLatestInventory = async (
  enterpriseId: string,
  planId: string,
  queryParams?: RequestParams
): Promise<InventoryProps | undefined> =>
  apiDashboardServer(endpoints.GET.EnterprisePlanServiceLatestInventory.route(enterpriseId, planId), {
    method: 'get',
    queryParams
  })

// Enterprise Offices
// -----------------
export type PostOfficeBody = Partial<OfficeWithoutBuilding> & { address: string }
export interface PostOfficeRes {
  office: Omit<OfficeProps, 'building'>
  building: BuildingProps
}
export type ImportOfficeRes = GetResponse<OfficeProps, { failedCount: number; successCount: number }> & {
  failedRecords: { error: CommutifiError; context?: PostOfficeBody }[]
}
export const postOffice = (body: PostOfficeBody, enterpriseId: string): Promise<PostOfficeRes> =>
  apiDashboardServer(endpoints.PUT.UpsertOffice.route(enterpriseId), {
    method: 'put',
    headers: JSON_HEADER,
    data: JSON.stringify(body)
  })
export const importEnterpriseOffices = (enterpriseId: string, csvFile: FormData): Promise<ImportOfficeRes> =>
  apiDashboardServer(endpoints.PUT.ImportOffices.route(enterpriseId), {
    method: 'put',
    headers: JSON_HEADER,
    data: csvFile
  })
export const fetchOffices = (
  kind: EnterpriseKind,
  entityId: string,
  queryParams: any
): Promise<GetResponse<Required<OfficeProps, 'building'>>> =>
  // Using kind here because there is still some slightly different logic on api-user endpoints
  apiDashboardServer(endpoints.GET.Offices.route(kind, entityId), {
    method: 'GET',
    queryParams
  })

export const fetchEnterpriseOffices = (
  enterpriseId: string,
  queryParams: RequestParams
): Promise<GetResponse<OfficeProps>> =>
  apiDashboardServer(endpoints.GET.EnterpriseOffices.route(enterpriseId), {
    method: 'get',
    queryParams
  })

// Enterprises Payment methods
// ---------------------------
export const postEnterprisePaymentMethods = (
  enterpriseId: string,
  data: PostPaymentMethodBody
): Promise<PaymentMethod> =>
  apiDashboardServer(endpoints.POST.PaymentMethods.route(enterpriseId), {
    method: 'post',
    data
  })

export const setEnterprisePaymentMethodToDefault = (
  enterpriseId: string,
  paymentMethodId: string
): Promise<PaymentMethod> =>
  apiDashboardServer(endpoints.PATCH.SetEnterprisePaymentMethodDefault.route(enterpriseId, paymentMethodId), {
    method: 'patch'
  })

export const fetchEnterprisePaymentMethods = (
  id: string,
  queryParams: RequestParams
): Promise<GetResponse<PaymentMethod>> =>
  apiDashboardServer(endpoints.GET.EnterprisePaymentMethods.route(id), {
    method: 'get',
    queryParams
  })

// Enterprise Notification Templates
// ----------------------------------
export const fetchEnterpriseNotificationTemplate = (
  enterpriseId: string,
  queryParams?: NotificationTemplateParams
): Promise<GetResponse<EnterpriseNotificationTemplatesProps>> =>
  apiDashboardServer(endpoints.GET.EnterpriseNotificationTemplates.route(enterpriseId), {
    method: 'GET',
    queryParams
  })

interface NotificationTemplateParams extends RequestParams {
  type?: string
}

export const putNotificationTemplate = (
  enterpriseId: string,
  body: Partial<EnterpriseNotificationTemplatesProps>
): Promise<EnterpriseNotificationTemplatesProps> =>
  apiDashboardServer(endpoints.PUT.EnterpriseNotificationTemplates.route(enterpriseId), {
    method: 'PUT',
    data: body
  })

// Enterprise compliance: DDOT submissions
// ----------------------------------------
export const fetchAdminDDOTSubmissions = (
  orgId: string,
  queryParams: RequestParams & Partial<Submission>
): Promise<GetResponse<Submission>> =>
  apiDashboardServer(endpoints.GET.AdminSubmissions.route(orgId), {
    method: 'get',
    queryParams
  })

export const fetchAdminDDOTSubmission = (
  organizationId: string,
  submissionId: string,
  queryParams: RequestParams & Partial<Submission>
): Promise<Submission> =>
  apiDashboardServer(endpoints.GET.AdminSubmission.route(organizationId, submissionId), {
    method: 'get',
    queryParams
  })

export const patchSubmissionAdmin = (
  orgId: string,
  submissionId: string,
  data: Partial<Omit<Submission, 'id'>>
): Promise<Submission> =>
  apiDashboardServer(endpoints.PATCH.SubmissionAdmin.route(orgId, submissionId), {
    method: 'patch',
    headers: JSON_HEADER,
    data
  })

// Enterprise MISC. endpoints
// --------------------------
export const sendEmployeeSurveyInvite = (entityId: string, data = {}) =>
  apiDashboardServer(endpoints.PATCH.OrganizationAccountInvite.route(entityId), {
    method: 'patch',
    headers: JSON_HEADER,
    data: JSON.stringify(data)
  })

export const fetchGroups = (entityId: string, queryParams: Record<string, any>) =>
  apiDashboardServer(endpoints.GET.OrganizationLegacyGroups.route(entityId), {
    method: 'get',
    queryParams
  })

export const fetchOrganizationMetrics = (id: string, queryParams: { enterpriseId?: string }) =>
  apiDashboardServer(endpoints.GET.OrganizationMetrics.route(id), {
    method: 'GET',
    queryParams
  })
