import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowRight } from '@commutifi-fe/custom-icons/regular'
import { Typography as AntdTypography } from 'antd'
import type { LinkProps as AntdLinkProps } from 'antd/es/typography/Link'
import type { ParagraphProps as AntdParagraphProps } from 'antd/es/typography/Paragraph'
import type { TextProps } from 'antd/es/typography/Text'
import type { TitleProps } from 'antd/es/typography/Title'
import cx from 'classnames'
import { createKeydownFromClick } from 'keydown-from-click'
import { Skeleton } from '../Skeleton'
import './styles.scss'

const { Link, Paragraph, Text, Title } = AntdTypography

enum FontWeight {
  Medium = 'medium',
  SemiBold = 'semi-bold',
  Bold = 'bold',
  Black = 'black'
}

enum TextAlign {
  Left = 'left',
  Right = 'right',
  Center = 'center',
  Justify = 'justify'
}

type FontWeightType = `${FontWeight}`

enum StyleGuides {
  Marketing = 'marketing',
  Dashboard = 'dashboard'
}

interface TextFormatting {
  capitalize?: boolean
  uppercase?: boolean
  lowercase?: boolean
}

interface CommonProps {
  styleguide?: `${StyleGuides}`
  /**
   * Apply styling of a specific heading but keep
   * the semantic used with prop 'level'
   */
  variant?: 'h1' | 'h2' | 'h3' | 'h4'
  textAlign?: TextAlign | `${TextAlign}`
}

export interface TitleContainerProps extends TitleProps, TextFormatting, CommonProps {
  bold?: boolean
  loading?: boolean
}

declare const SizeTypes: ['lead', 'regular', 'small']
export declare type SizeType = (typeof SizeTypes)[number]

interface TextContainerProps extends Omit<TextProps, 'strong'>, TextFormatting, CommonProps {
  /**
   * Display: block
   */
  block?: boolean
  size?: SizeType
  strong?: FontWeightType
}

export interface LinkProps extends Omit<AntdLinkProps, 'strong'>, TextFormatting, Pick<CommonProps, 'styleguide'> {
  size?: SizeType
  strong?: FontWeightType
  styleguide?: `${StyleGuides}`
}

declare const DirectionTypes: ['horizontal', 'vertical']
export declare type DirectionType = (typeof DirectionTypes)[number]
export interface ParagraphProps extends Omit<AntdParagraphProps, 'strong' | 'direction'>, TextFormatting {
  size?: SizeType
  // Weather to display the expand button beside the text (horizontal) or under it (vertical)
  direction?: DirectionType
  strong?: FontWeightType
  textAlign?: TextAlign
}

const applyTextFormattingClassNames = ({
  capitalize,
  uppercase,
  lowercase
}: Pick<TitleContainerProps, 'uppercase' | 'lowercase' | 'capitalize'>) => ({
  [`${AppTypographyClassName}--capitalize`]: capitalize,
  [`${AppTypographyClassName}--uppercase`]: uppercase,
  [`${AppTypographyClassName}--lowercase`]: lowercase
})

const AppTitleClassName = 'app-typography-title'
const TitleContainer = React.forwardRef<HTMLElement, TitleContainerProps>(
  (
    {
      className,
      bold = true,
      level = 1,
      style,
      styleguide = StyleGuides.Dashboard,
      loading,
      children,
      variant,
      capitalize,
      lowercase,
      uppercase,
      textAlign,
      ...props
    },
    ref
  ) => (
    <Title
      ref={ref}
      className={cx(
        AppTitleClassName,
        !bold && `${AppTitleClassName}--normal-fw`,
        styleguide === StyleGuides.Marketing && `${AppTitleClassName}--marketing`,
        variant && `${AppTitleClassName}-variant--${variant}`,
        applyTextFormattingClassNames({ capitalize, lowercase, uppercase }),
        textAlign && `${AppTypographyClassName}-text-align--${textAlign}`,
        className
      )}
      level={level}
      style={style}
      {...props}
    >
      {loading ? <Skeleton.Input /> : children}
    </Title>
  )
)
TitleContainer.displayName = 'Title'

const getFontWeightStyle = (strong: `${FontWeight}` | undefined) => ({
  ...(strong === FontWeight.Medium && { fontWeight: 500 }),
  ...(strong === FontWeight.SemiBold && { fontWeight: 600 }),
  ...(strong === FontWeight.Bold && { fontWeight: 700 }),
  ...(strong === FontWeight.Black && { fontWeight: 900 })
})

const AppTypographyClassName = 'app-typography'
const TextContainer = React.forwardRef<HTMLElement, TextContainerProps>(
  (
    {
      className,
      size = 'regular',
      strong,
      styleguide = StyleGuides.Dashboard,
      style,
      block,
      variant,
      capitalize,
      lowercase,
      uppercase,
      textAlign,
      ...props
    },
    ref
  ) => (
    <Text
      ref={ref}
      className={cx(
        AppTypographyClassName,
        `${AppTypographyClassName}--${size}`,
        block && `${AppTypographyClassName}--block`,
        styleguide === StyleGuides.Marketing && `${AppTypographyClassName}--marketing`,
        variant && `${AppTitleClassName} ${AppTitleClassName}-variant--${variant}`,
        applyTextFormattingClassNames({ capitalize, lowercase, uppercase }),
        textAlign && `${AppTypographyClassName}-text-align--${textAlign}`,
        className
      )}
      style={{
        ...style,
        ...getFontWeightStyle(strong)
      }}
      strong={strong === FontWeight.SemiBold}
      {...props}
    />
  )
)
TextContainer.displayName = 'Text'

const ParagraphContainer = React.forwardRef<HTMLElement, ParagraphProps>(
  (
    {
      className,
      size = 'regular',
      direction = 'horizontal',
      strong,
      style,
      capitalize,
      lowercase,
      uppercase,
      textAlign,
      ...props
    },
    ref
  ) => (
    <Paragraph
      ref={ref}
      className={cx(
        AppTypographyClassName,
        `${AppTypographyClassName}--${size}`,
        direction === 'vertical' && `${AppTypographyClassName}-expand--bottom`,
        applyTextFormattingClassNames({ capitalize, lowercase, uppercase }),
        textAlign && `${AppTypographyClassName}-text-align--${textAlign}`,
        className
      )}
      strong={strong === FontWeight.SemiBold}
      style={{
        ...style,
        ...getFontWeightStyle(strong)
      }}
      {...props}
    />
  )
)
ParagraphContainer.displayName = 'Paragraph'

const LinkContainer = React.forwardRef<HTMLElement, LinkProps>(
  ({ className, strong, size = 'regular', style, onClick, capitalize, lowercase, uppercase, ...props }, ref) => (
    <Link
      ref={ref}
      role="link"
      className={cx(
        `${AppTypographyClassName}--${size}`,
        applyTextFormattingClassNames({ capitalize, lowercase, uppercase }),
        className
      )}
      strong={strong === FontWeight.SemiBold}
      style={{
        display: 'inline-flex',
        alignItems: 'center',
        ...style,
        ...getFontWeightStyle(strong)
      }}
      onClick={onClick}
      onKeyDown={onClick ? createKeydownFromClick(onClick, { keys: ['Enter'] }) : undefined}
      {...props}
    />
  )
)
LinkContainer.displayName = 'Link'

function FormattedEOL({ children }: { children: string }) {
  return children.split('\n').map((paragraph, i) => (
    <React.Fragment key={i}>
      {paragraph}
      <br />
    </React.Fragment>
  ))
}

/**
 * This class is a wrap around [antd Typography](https://ant.design/components/typography/) component extending some of
 * the properties to follow our styling guidelines.
 *
 * Please refer to antd documentation for more details.
 *
 * It is used to insure we are consistent in our use of different text styles and that we normalize
 * our title and text sizes, colors and more. It also makes it easy to use a specific type of text,
 * paragraph, title, etc. into any other component
 */
const InternalTypography = React.forwardRef<HTMLElement>((props, ref) => (
  <AntdTypography ref={ref} {...props} className="app-typography" />
))
InternalTypography.displayName = 'Typography'

type TypographyType = typeof InternalTypography
interface TypographyInterface extends TypographyType {
  Link: typeof LinkContainer
  Paragraph: typeof ParagraphContainer
  Text: typeof TextContainer
  Title: typeof TitleContainer
  ExternalLink: typeof ExternalLinkContainer
  EOLText: typeof FormattedEOL
  displayName?: string
  defaultProps?: typeof AntdTypography.defaultProps
}
export const Typography = InternalTypography as TypographyInterface

Typography.Link = LinkContainer
Typography.Paragraph = ParagraphContainer
Typography.Text = TextContainer
Typography.Title = TitleContainer
Typography.EOLText = FormattedEOL
Typography.displayName = AntdTypography.displayName
Typography.defaultProps = AntdTypography.defaultProps

function LinkArrowIcon({ className }: { className?: string }) {
  return <FontAwesomeIcon icon={faLongArrowRight} transform={{ rotate: -45 }} className={className} />
}

const ExternalLinkContainer = React.forwardRef<
  HTMLElement,
  LinkProps & { iconPosition?: 'prefix' | 'suffix'; icon?: React.ReactNode }
>(({ children, iconPosition = 'suffix', icon, style, ...props }, ref) => (
  <LinkContainer
    ref={ref}
    underline
    target="__blank"
    strong="semi-bold"
    style={{ display: 'inline-flex', ...style }}
    {...props}
    data-testid="external-link"
  >
    <span>
      {iconPosition === 'prefix' ? (
        icon === false ? null : (
          <span>{icon || <LinkArrowIcon className="u-margin-right--small" />}</span>
        )
      ) : null}
      {children}
      {iconPosition === 'suffix' ? (
        icon === false ? null : (
          <span>{icon || <LinkArrowIcon className="u-margin-left--small" />}</span>
        )
      ) : null}
    </span>
  </LinkContainer>
))
ExternalLinkContainer.displayName = 'ExternalLink'

Typography.ExternalLink = ExternalLinkContainer
export default Typography
