import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storageSession from 'redux-persist/es/storage/session'
import steps from './steps/reducer'
import userLocation from './userLocation/reducer'
import planSubscriptions from './planSubscriptions/reducer'
import incentivePrograms from './incentivePrograms/slice'
import { mapReducer } from './mapAnalytics'
import { analyticFiltersReducer } from './analyticsFilters'

export default combineReducers({
  steps,
  userLocation,
  planSubscriptions,
  incentivePrograms,
  mapAnalytics: mapReducer,
  analyticsFilters: persistReducer({ storage: storageSession, key: 'analyticsFilters' }, analyticFiltersReducer)
})
