import { createContext, Dispatch, useContext } from 'react'
import {
  OrganizationFilterActionType,
  OrganizationFiltersState,
  PartialEnterprise,
  setEnterpriseFilter
} from './actions'

export const OrganizationFiltersContext = createContext<
  [OrganizationFiltersState & { enterprises: PartialEnterprise[] }, Dispatch<OrganizationFilterActionType>] | null
>(null)

export const useOrganizationFilters = () => {
  const context = useContext(OrganizationFiltersContext)
  if (!context) {
    throw new Error('useOrganizationFilters must be used within a OrganizationFiltersProvider')
  }
  const [state, dispatch] = context

  return {
    state,
    currentEnterprise: state.enterprisesById?.[state.enterpriseId || ''],
    dispatch,
    ActionTypes: {
      setEnterpriseFilter
    }
  }
}
