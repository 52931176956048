import { takeEvery, call, put, select, all } from 'redux-saga/effects'
import FETCH_STATUS from 'constants/global'
import * as actions from './actions'
import { getFetchStatus } from './selectors'
import { fetchProvider } from 'api/modules/routes/api'
import types from './types'

import * as schemas from 'store/schemas'
import { normalize } from 'normalizr'
import uniq from 'lodash/uniq'

export const providersSagas = [
  takeEvery(types.FETCH_PROVIDER, getProvider),
  takeEvery(types.FETCH_PROVIDERS, getProviders)
]

export function* getProviders({ ids }) {
  // Remove duplicated ids to fetch only one time each provider
  const uniqueIds = yield uniq(ids)
  yield all(uniqueIds.map((id) => call(getProvider, { id })))
}

export function* getProvider({ id }) {
  try {
    if (!id) return

    const fetchStatus = yield select(getFetchStatus)
    if (fetchStatus[id] === FETCH_STATUS.LOADED) {
      return yield put(actions.fetchProviderCancel())
    }

    yield put(actions.fetchProviderRequest(id))
    const res = yield call(fetchProvider, id)
    const normalizedProvider = yield normalize(res.data, schemas.provider)
    yield put(actions.fetchProviderSuccess(id, normalizedProvider))
  } catch (error) {
    yield put(actions.fetchProviderFailure(id, error))
  }
}
