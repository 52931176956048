import {
  captureException,
  logger,
  init as loggerInit,
  identify,
  getSessionUrl,
  reduxMiddleware
} from '@commutifi-fe/logger'
import config from 'config/index'

const appId = config.logRocket

export const init = ({ hidePersonalData }: { hidePersonalData: boolean } = { hidePersonalData: true }) => {
  loggerInit(appId, { hidePersonalData })
}

export { getSessionUrl }

export { captureException, logger, identify, reduxMiddleware }
