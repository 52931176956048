import { ModeName, ModeCategory, ModeFamily, Stop } from '@commutifi/modes'
import {
  AugmentedFontAwesomeIconProps,
  faBus,
  faTrain,
  faTramway,
  faSubway,
  faFerry,
  faCar,
  faParking,
  faRideshare,
  faCarpool,
  faCarshare,
  faBike,
  faBikeshare,
  faVanpool,
  faScooter,
  faWalk,
  faSharedScooter,
  faRemote,
  faMapMarker,
  faElectricBike
} from '@commutifi-fe/custom-icons/solid'
import { faRoute } from '@commutifi-fe/custom-icons/regular'
import { faDumbbell, faHandshake, faSchool, faShoppingCart, faStar } from '@fortawesome/free-solid-svg-icons'
import { faTrees } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ServiceType } from '@commutifi/constants/Routes'
import { CombinedModes, ModelingPotentialMode } from '@commutifi-fe/constants'
import { faBan, faCoffeeTogo } from '@fortawesome/pro-regular-svg-icons'

/**
 * Return corresponding svg (with fallback case) for a specified service
 * @param mode - represents a mode name (ridesharing, carpooling, walking, etc.) which are the specific modes without sub categories,
 *                        mode family (transit, activity, vehicle, etc.),
 *                        mode category (public_transit, shared_ride, bike) that regroups multiple mode names
 *                        or activity (coffee, gym, etc.)
 */
export const getModeImage = (mode: string | undefined | null = '', props?: Partial<AugmentedFontAwesomeIconProps>) => {
  switch ((mode || '').toLowerCase()) {
    case ModeName.Bus:
    case ModeCategory.PublicTransit:
    case ModeFamily.Transit:
      return <FontAwesomeIcon icon={faBus} {...props} />
    case ModeName.Train:
      return <FontAwesomeIcon icon={faTrain} {...props} />
    case ModeName.Tramway:
      return <FontAwesomeIcon icon={faTramway} {...props} />
    case ModeName.Subway:
      return <FontAwesomeIcon icon={faSubway} {...props} />
    case ModeName.Ferry:
      return <FontAwesomeIcon icon={faFerry} {...props} />
    case ModeName.Drive:
    case ModeCategory.Drive:
    case ModeFamily.Vehicle:
      return <FontAwesomeIcon icon={faCar} {...props} />
    case 'parking':
    case 'park':
    case Stop.Parking:
      return <FontAwesomeIcon icon={faParking} {...props} />
    case ModeName.Rideshare:
    case ModeName.Taxi:
      return <FontAwesomeIcon icon={faRideshare} {...props} />
    case ModeCategory.Carpool:
    case ModeName.CarpoolDriver:
    case ModeName.CarpoolPassenger:
      return <FontAwesomeIcon icon={faCarpool} {...props} />
    case ModeName.Carshare:
    case ModeCategory.SharedRide:
      return <FontAwesomeIcon icon={faCarshare} {...props} />
    case 'cash-out':
    case ServiceType.benefit:
    case ServiceType.program:
    case ServiceType.amenity:
      return <FontAwesomeIcon icon={faStar} {...props} />
    case ModeName.Bike:
    case ModeCategory.Bike:
      return <FontAwesomeIcon icon={faBike} {...props} />
    case ModelingPotentialMode.EBiking:
      return <FontAwesomeIcon icon={faElectricBike} {...props} />
    case ModeName.Bikeshare:
      return <FontAwesomeIcon icon={faBikeshare} {...props} />
    case ModeName.Walk:
    case ModeCategory.Walk:
      return <FontAwesomeIcon icon={faWalk} {...props} />
    case ModeName.Shuttle:
    case ModeName.Vanpool:
      return <FontAwesomeIcon icon={faVanpool} {...props} />
    case ModeName.Scooter:
      return <FontAwesomeIcon icon={faScooter} {...props} />
    // In the mode library it is the only one in camelCase. We should do a better
    // fix but it is not that simple to change a mode name that already exists
    case ModeName.SharedScooter.toLowerCase():
      return <FontAwesomeIcon icon={faSharedScooter} {...props} />
    case ModeCategory.Remote:
    case ModeName.Remote:
      return <FontAwesomeIcon icon={faRemote} {...props} />
    case ServiceType.carbonOffset:
      return <FontAwesomeIcon icon={faTrees} {...props} />
    case 'driveAndTransit':
    case 'multiple':
    case CombinedModes.DriveAndTransit:
    case CombinedModes.MixedModes:
      return <FontAwesomeIcon icon={faRoute} {...props} />
    case ModeName.OtherTransit:
    case ServiceType.activity:
    case Stop.DropOffKids:
      return <FontAwesomeIcon icon={faSchool} {...props} />
    case Stop.Errands:
      return <FontAwesomeIcon icon={faShoppingCart} {...props} />
    case Stop.FoodDrink:
      return <FontAwesomeIcon icon={faCoffeeTogo} {...props} />
    case Stop.Meetings:
      return <FontAwesomeIcon icon={faHandshake} {...props} />
    case Stop.PhysicalActivity:
      return <FontAwesomeIcon icon={faDumbbell} {...props} />
    case Stop.Other:
      return <FontAwesomeIcon icon={faMapMarker} {...props} />
    default:
      return <FontAwesomeIcon icon={faBan} {...props} />
  }
}

/**
 * Return corresponding svg (with fallback case) for a specified mode
 * @param mode - represents a mode name (ridesharing, carpooling, walking, etc.) which are the specific modes without sub categories,
 *               mode family (transit, activity, vehicle, etc.),
 *               mode category (public_transit, shared_ride, bike) that regroups multiple mode names
 */
export const ModeIcon = ({ mode, ...props }: ModeIconProps) => getModeImage(mode, props)

interface ModeIconProps extends Omit<Partial<AugmentedFontAwesomeIconProps>, 'mode'> {
  mode: string | null | undefined
}
