import moment from 'moment-timezone'
import { DaysValueEnum } from 'shared/interfaces/Commute'
import { ascendingCompare } from 'utils/helpers'
import { isConsecutiveNumbers } from 'utils/index'
import { logger } from 'utils/logRocket'

export const MONTH_UNIT = 'month'
export const DAY_UNIT = 'day'
export const YEAR_UNIT = 'year'

/**
 * Get a formatted day in proper locale from a day STRING.
 * We save the configured locale to make sure to ALWAYS use the same locale (en)
 * to process the days values. This is only to make it more simple to handle
 * on the UI level by being confident we can handle everything the same way cause it's in english
 * @param day - Day string, needs to be from english moment weekdays: Monday, Tuesday, Wednesday, etc.
 * @param format - Output format of the localized day
 * @returns localized day string
 */
export const getDayLocale = (day: string | undefined, format = 'dddd') => {
  if (!day) {
    return
  }
  const configuredLocale = moment.locale()
  const momentDay = moment().locale('en').day(day)
  return momentDay.isValid() ? momentDay.locale(configuredLocale).format(format) : day
}

export const getDayEn = (day: string, format = 'dddd') => moment().day(day).locale('en').format(format)

/**
 * Process the numeric value of an 'en' locale day (Monday, Tuesday, etc.)
 * @param day - Day string, needs to be from english moment weekdays: Monday, Tuesday, Wednesday, etc.
 * @returns Number between 0 and 7 representing day number. Also influenced by `dow` config in moment object
 */
export const getDayValue = (day: string) => moment().locale('en').day(day).weekday()

/**
 * Get a formatted day in proper locale from a day NUMBER value.
 * Very similar to getDayLocale but from a number value representing a day
 * We also save the configured locale to make sure to ALWAYS use the same locale (en)
 * to process the days values. For full comment see getDayLocale
 * @param day - Day number (Between 0 and 7)
 * @param format - Output format of the localized day
 * @returns localized day string
 */
export const getDayString = (day: number, format?: string) => {
  const configuredLocale = moment.locale()
  return moment().locale('en').weekday(day).locale(configuredLocale).format(format)
}

export const displayDays = (days: DaysValueEnum[] | undefined, splitWord = '') => {
  if (!days || days.length === 0) {
    return
  }

  const sortedDayValues = days.map(getDayValue).sort(ascendingCompare)
  if (sortedDayValues.length <= 2) {
    return sortedDayValues.map((dayValue) => getDayString(dayValue, 'dddd')).join(', ')
  }

  if (isConsecutiveNumbers(sortedDayValues)) {
    return `${getDayString(sortedDayValues[0], 'dddd')} ${splitWord} ${getDayString(
      sortedDayValues[sortedDayValues.length - 1],
      'dddd'
    )}`
  }
  return sortedDayValues.map((day) => getDayString(day, 'ddd')).join(', ')
}

export interface DateBoundariesOptions {
  /**
   * Timezone to apply before converting to UTC time
   */
  timezone?: string
  /**
   * Set this value to determine the start of which unit of time you want to get.
   */
  startOf?: moment.unitOfTime.StartOf
  // Set this value to determine the end of which unit of time you want to get.
  endOf?: moment.unitOfTime.StartOf
}

/**
 *
 * @param date - Date we want to use to get the 'startOf' or 'endOf' 'week | day | etc.' (recognized RFC2822 or ISO format)
 * @param opts - Options to pass to moment functions. See interface
 * @returns Moment object (for more flexibility) with ready to use format function. It returns in UTC
 *                           for convenience because we only use timezones for UI display (which normally don't use boundaries -- startOf / endOf)
 */
export const formatDateBoundaries = (date: moment.MomentInput, opts?: DateBoundariesOptions): moment.Moment => {
  const defaultOptions = {
    timezone: 'UTC',
    startOf: 'day' as moment.unitOfTime.StartOf,
    endOf: undefined
  }

  const options = { ...defaultOptions, ...opts }
  const momentBoundaryFunction = options.endOf ? 'endOf' : 'startOf'
  const { timezone, startOf, endOf } = options

  if (timezone === 'UTC') {
    logger.warn('No timezone passed in parameter, falling back to UTC which will give invalid times for the user')
  }

  const timezoneTime = timezone ? moment.tz(date, timezone) : moment(date)

  if (!timezoneTime.isValid()) {
    logger.warn('Invalid date', date)
  }

  const utcBoundedMoment = timezoneTime[momentBoundaryFunction](
    momentBoundaryFunction === 'startOf' ? startOf : endOf || startOf
  ).utc()

  return utcBoundedMoment
}

interface DateWithFormat {
  date: string
  format: string
}
export const compareDates = (t1: string | DateWithFormat, t2: string | DateWithFormat) => {
  const firstDate = moment(typeof t1 === 'object' ? t1.date : t1, typeof t1 === 'object' ? t1.format : undefined)
  const secondDate = moment(typeof t2 === 'object' ? t2.date : t2, typeof t2 === 'object' ? t2.format : undefined)

  if (!firstDate.isValid() || !secondDate.isValid()) {
    logger.warn('Skipping date compare because of invalid moment date: ', { firstDate: t1, secondDate: t2 })
    return -1
  }

  return firstDate.valueOf() - secondDate.valueOf()
}

export const generateDateRange = (
  startDate: string | Date | undefined,
  endDate: string | Date | undefined,
  opts: {
    format?: string
    timezone: string
    formatEndDate?: (startDate: moment.Moment, endDate: moment.Moment) => string
  }
): [] | [string] | [string, string] => {
  if (!startDate) {
    return []
  }

  const options = { format: opts.format || 'YYYY-MM-DD', ...opts }
  const momentStart = moment.tz(startDate, options.timezone)
  if (!momentStart.isValid()) {
    return []
  }

  const momentEnd = moment.tz(endDate, options.timezone)
  if (!endDate || !momentEnd.isValid()) {
    return [momentStart.format(options.format)]
  }

  return [
    momentStart.format(options.format),
    momentEnd.format(options.formatEndDate ? options.formatEndDate(momentStart, momentEnd) : options.format)
  ]
}

/**
 * Gets the middle of the month. This function does not guarantee the time of the day
 * so make sure to properly set the time you want after calling this function
 *
 * @example If round = 'up'.
 *          01 May 2021 has '31 days / 2 = 16 (rounded)'. Result should be
 *          the 16th day of may.
 * @example If round = 'down'.
 *          01 May 2021 has '31 days / 2 = 15 (rounded)'. Result should be
 *          the 15th day of may.
 * @example if round = 'up'
 *          01 June 2021 has '30 days / 2 = 15'. Result should be
 *          the 15th day of may.
 *
 * @param date - date to get the middle of the month of
 * @returns the middle of the month based on the date rounded up (ie. a month with 31 days will return day 16 of that month)
 */
export const getMiddleOfTheMonth = (date: moment.Moment, round: 'up' | 'down'): moment.Moment => {
  const daysUntilTheMiddle = round === 'up' ? Math.ceil(date.daysInMonth() / 2) : Math.floor(date.daysInMonth() / 2)
  return (
    date
      .clone()
      .startOf(MONTH_UNIT)
      .add(daysUntilTheMiddle, 'days')
      // We start on the first of the month so after we add 15 or 16 days we'll
      // be on the 16th or the 17th. That's why we go back one day
      .subtract(1, 'day')
  )
}

export const getMaxDate = (dateA: string | undefined, dateB: string | undefined) =>
  moment.max([moment(dateA), moment(dateB)].filter((date) => moment(date).isValid())).utc()
