import { CommutifiIconDefinition } from '../types'

export const faElectricPlug: CommutifiIconDefinition = {
  prefix: 'fas',
  iconName: 'electric-plug',
  icon: [
    512,
    512,
    [],
    '22',
    'M 418.460938 166.398438 L 418.460938 192 C 418.460938 199.070312 412.726562 204.800781 405.652344 204.800781 L 392.84375 204.800781 L 392.84375 264.703125 C 392.863281 291.871094 382.082031 317.933594 362.867188 337.152344 L 312.90625 388.351562 C 299.859375 400.878906 282.800781 408.40625 264.742188 409.601562 L 264.742188 499.199219 C 264.742188 506.269531 259.007812 512 251.933594 512 L 175.074219 512 C 168 512 162.261719 506.269531 162.261719 499.199219 L 162.261719 409.601562 C 144.105469 408.195312 127.035156 400.390625 114.097656 387.585938 L 64.136719 336.382812 C 45.113281 317.351562 34.34375 291.601562 34.164062 264.703125 L 34.164062 204.800781 L 21.351562 204.800781 C 14.277344 204.800781 8.542969 199.070312 8.542969 192 L 8.542969 166.398438 C 8.542969 159.332031 14.277344 153.601562 21.351562 153.601562 L 85.402344 153.601562 L 85.402344 12.800781 C 85.402344 5.730469 91.136719 0 98.214844 0 L 123.832031 0 C 130.90625 0 136.644531 5.730469 136.644531 12.800781 L 136.644531 153.601562 L 290.363281 153.601562 L 290.363281 12.800781 C 290.363281 5.730469 296.097656 0 303.171875 0 L 328.792969 0 C 335.867188 0 341.601562 5.730469 341.601562 12.800781 L 341.601562 153.601562 L 405.652344 153.601562 C 412.726562 153.601562 418.460938 159.332031 418.460938 166.398438 Z M 418.460938 166.398438 '
  ]
}
