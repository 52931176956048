// Based on https://github.com/Microsoft/TypeScript/issues/4813
// We import all func in actions to export it after because export * as alias from 'yourfile' give a syntax error
import * as userMetricsActions from './actions'

import * as userMetricsSelectors from './selectors'

import userMetricsReducer from './reducer'
export { userMetricsActions }
export { userMetricsSelectors }
export default userMetricsReducer

export { userMetricsSagas } from './sagas'
