import { NormalizedSchema } from 'normalizr'
import { NormalizedData } from 'store/schemas'
import sessionStorage from 'utils/sessionStorage'

export interface PartialEnterprise {
  id: string
  name: string
  employeesCount: number
}

/**
 * Reference https://kentcdodds.com/blog/application-state-management-with-react
 */
export const IS_FETCHING = 'IS_FETCHING'
export const SET_ENTERPRISES = 'SET_ENTERPRISES'
export const FAILED = 'FAILED'
export const ENTERPRISE_ID_FILTER = 'ENTERPRISE_ID_FILTER'
export const CLEAR_FILTERS = 'CLEAR_FILTERS'

export interface IsFetchingActionType {
  type: typeof IS_FETCHING
}
export interface SetEnterprisesActionType {
  type: typeof SET_ENTERPRISES
  payload: NormalizedSchema<{ enterprises: NormalizedData<PartialEnterprise> }, string[]>
}
export interface FailedActionType {
  type: typeof FAILED
  error: any
}
export interface EnterpriseIdFilterActionType {
  type: typeof ENTERPRISE_ID_FILTER
  enterpriseId: string | undefined
}
export interface ClearFiltersActionType {
  type: typeof CLEAR_FILTERS
}
export type OrganizationFilterActionType =
  | IsFetchingActionType
  | SetEnterprisesActionType
  | FailedActionType
  | EnterpriseIdFilterActionType
  | ClearFiltersActionType

export interface OrganizationFiltersState {
  isFetching: boolean
  loaded: boolean
  enterprisesIds: string[] | null
  enterpriseId: string | null | undefined
  enterprisesById: Record<string, PartialEnterprise> | null
}

export const FilterId = 'enterpriseFilterId'
export const organizationFiltersReducer = (state: OrganizationFiltersState, action: OrganizationFilterActionType) => {
  switch (action.type) {
    case IS_FETCHING: {
      return { ...state, isFetching: true }
    }
    case SET_ENTERPRISES: {
      return {
        ...state,
        enterprisesIds: action.payload.result,
        enterprisesById: action.payload.entities.enterprises,
        loaded: true
      }
    }
    case FAILED: {
      return { ...state, isFetching: false, loaded: false }
    }
    case ENTERPRISE_ID_FILTER: {
      if (action.enterpriseId) {
        sessionStorage.put(FilterId, action.enterpriseId)
      } else {
        sessionStorage.remove(FilterId)
      }
      return { ...state, enterpriseId: action.enterpriseId }
    }
    case CLEAR_FILTERS: {
      sessionStorage.remove(FilterId)
      return { ...state, enterpriseId: null }
    }
    default: {
      throw new Error('Unsupported action type')
    }
  }
}

export const setEnterpriseFilter = (enterpriseId: string | undefined): EnterpriseIdFilterActionType => ({
  type: ENTERPRISE_ID_FILTER,
  enterpriseId
})
