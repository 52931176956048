import { AnyAction } from 'redux'
import { PersistConfig, persistReducer } from 'redux-persist'
import { PersistPartial } from 'redux-persist/es/persistReducer'
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2'
import storage from 'redux-persist/es/storage'
import createSagaMiddleware from 'redux-saga'
import { captureError } from 'utils/helpers/errors'
import * as authTypes from './modules/auth/types'
import reducer, { persistBlacklist, RootState } from './reducer'
import rootSaga from './sagas'
import { createReduxEnhancer } from '@sentry/react'
import { configureStore, Tuple } from '@reduxjs/toolkit'
import { reduxMiddleware } from 'utils/logRocket'

// Thanks to https://blog.reactnativecoach.com/the-definitive-guide-to-redux-persist-84738167975
// for the persistance state process

export const persistConfig: PersistConfig<RootState> = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  blacklist: persistBlacklist
}

const logRocketConfig = {
  stateSanitizer: (state: RootState) => ({
    ...state,
    form: 'sanitized',
    account: {
      ...state.account,
      name: 'sanitized',
      email: 'sanitized',
      internalId: 'sanitized',
      phoneNumber: 'sanitized',
      stripeCustomerId: 'sanitized',
      streetNumber: 'sanitized',
      street: 'sanitized',
      address: 'sanitized',
      office: 'sanitized'
    }
  }),
  actionSanitizer: (action: AnyAction) => {
    if (action.type === authTypes.LOGIN_REQUEST) {
      return {
        ...action,
        auth: {
          ...action.auth,
          password: 'sanitized'
        }
      }
    }
    if (action.type === authTypes.CREATE_PASSWORD_REQUEST) {
      return {
        ...action,
        values: {
          ...action.values,
          auth: {
            ...action.auth,
            password: 'sanitized'
          }
        }
      }
    }
    return action
  }
}

const sentryReduxEnhancer = createReduxEnhancer({
  // Optionally pass options listed below
  actionTransformer: logRocketConfig.actionSanitizer,
  stateTransformer: logRocketConfig.stateSanitizer
})

export default (
  initialState: Partial<RootState> & PersistPartial = { _persist: { version: -1, rehydrated: false } }
) => {
  // ======================================================
  // Middleware Configuration
  // ======================================================
  const sagaMiddleware = createSagaMiddleware({
    onError: (error, errorInfo) => captureError(error, { extra: { errorInfo } })
  })
  const middleware = [reduxMiddleware(logRocketConfig), sagaMiddleware]

  // ======================================================
  // Store Enhancers
  // ======================================================

  // ======================================================
  // Store Instantiation and HMR Setup
  // ======================================================
  const store = configureStore({
    reducer: persistReducer<RootState>(persistConfig, reducer),
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) =>
      new Tuple(
        ...getDefaultMiddleware({
          // Since we integrate with existing state not created with redux toolkit
          serializableCheck: false
        }),
        ...middleware
      ),
    enhancers: (getDefaultEnhancers) => new Tuple(...getDefaultEnhancers(), sentryReduxEnhancer)
  })

  sagaMiddleware.run(rootSaga)

  return store
}
