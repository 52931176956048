import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { updateOneAccount, fetchAccount, fetchMe as fetchMeHttpCall } from 'api/modules/accounts/api'
import { FetchMeData } from 'shared/interfaces'
import { callApiSaga } from 'store/callApiSaga'
import * as schemas from 'store/schemas'
import * as types from './types'
import * as actions from './actions'
import type { FetchMeAction } from './actions'
import { Account } from '@commutifi-fe/interfaces'

export const updateAccount = (
  id: string,
  body: Partial<Account>,
  promise?: { resolve: typeof Promise.resolve; reject: typeof Promise.reject }
) => ({
  type: types.UPDATE_ACCOUNT_TRIGGER,
  types: [types.UPDATE_ACCOUNT_REQUEST, types.UPDATE_ACCOUNT_SUCCESS, types.UPDATE_ACCOUNT_FAILURE],
  callAPI: () => updateOneAccount(id, body),
  payload: body,
  promise,
  normalize: (res: Account) => normalize(res, schemas.account)
})

export const triggerFetchAccount = (id: string, queryParams: Record<string, any>, promise?: typeof Promise) => ({
  type: types.FETCH_ACCOUNT_TRIGGER,
  types: [types.FETCH_ACCOUNT_REQUEST, types.FETCH_ACCOUNT_SUCCESS, types.FETCH_ACCOUNT_FAILURE],
  callAPI: () => fetchAccount(id, queryParams),
  payload: queryParams,
  promise,
  normalize: (res: Account): schemas.NormalizerResultForAccounts => normalize(res, schemas.account)
})

export function* fetchMe({ promise }: FetchMeAction) {
  try {
    const data: FetchMeData = yield call(fetchMeHttpCall)
    const account: Account = { ...data.account, ...data.metadata }
    yield put(actions.fetchMeSuccess(normalize(account, schemas.account)))

    if (promise) yield call(promise.resolve, data)
  } catch (error) {
    yield put(actions.fetchMeFailure(error))
    if (promise) yield call(promise.reject, error)
  }
}

export const accountSagas = [
  takeEvery(types.FETCH_ME_REQUEST, fetchMe),
  takeLatest(types.UPDATE_ACCOUNT_TRIGGER, callApiSaga),
  takeLatest(types.FETCH_ACCOUNT_TRIGGER, callApiSaga)
]
