import { combineReducers } from 'redux'
import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storageSession from 'redux-persist/es/storage/session'
import { DeckGLProps } from '@commutifi-fe/deck.gl/react'
import { ModelingOptionValue } from '@commutifi-fe/constants'
import { MapType, MapMetrics } from 'constants/analytics'
import { FETCH_ORGANIZATION_SUCCESS, IFetchOrganizationSuccess } from 'store/modules/enterprise/types'
import { analyticFiltersActions } from '../analyticsFilters'

type ViewStateType = Parameters<NonNullable<DeckGLProps['onViewStateChange']>>[0]['viewState']
type MapSelectedIndicator = MapMetrics | ModelingOptionValue
type MapAnalyticsState = {
  isConfigPanelCollapsed: boolean
  isLegendCollapsed: boolean
  mapType: MapType
  mapSelectedIndicator: MapSelectedIndicator | null
  /**
   * Specific to modeling for now
   */
  adoptionRatePerModelingOption?: Partial<Record<ModelingOptionValue, number>>
  viewState: ViewStateType
  is3DMode: boolean
}
export enum AvailableAnalyticStore {
  CurrentCommute = 'currentCommute',
  ActiveCommuteModeling = 'activeCommuteModeling',
  TransitModeling = 'transitModeling'
}

const baseDefaultState = {
  isConfigPanelCollapsed: false,
  isLegendCollapsed: false,
  viewState: {
    minZoom: 3,
    maxZoom: 15,
    pitch: 30,
    maxPitch: 75,
    bearing: -10,
    latitude: 39.8097343,
    longitude: -98.5556199,
    zoom: 3
  }
}

export const baseMapInitialState: MapAnalyticsState = {
  ...baseDefaultState,
  mapType: MapType.Density,
  mapSelectedIndicator: MapMetrics.CommuteScore,
  is3DMode: true
}
export const modelingMapInitialState: MapAnalyticsState = {
  ...baseDefaultState,
  mapType: MapType.Points,
  mapSelectedIndicator: null,
  is3DMode: false
}

export const initialStatePerStore = {
  [AvailableAnalyticStore.CurrentCommute]: baseMapInitialState,
  [AvailableAnalyticStore.ActiveCommuteModeling]: modelingMapInitialState,
  [AvailableAnalyticStore.TransitModeling]: modelingMapInitialState
}

const byEnterpriseInitialState: { enterpriseId: string | null; byId: Record<string, MapAnalyticsState | undefined> } = {
  enterpriseId: null,
  byId: {}
}

const fetchOrganizationSuccessReducer =
  (mapInitialState: MapAnalyticsState) =>
  (state: typeof byEnterpriseInitialState, action: IFetchOrganizationSuccess) => {
    const newEnterpriseId = action.response.result.enterprise || action.response.result.organization
    if (newEnterpriseId) {
      state.enterpriseId = newEnterpriseId
      if (!state.byId[newEnterpriseId]) {
        state.byId[newEnterpriseId] = mapInitialState
      }
    }
  }

const getCurrentEnterprise = (state: typeof byEnterpriseInitialState) => state.byId[state.enterpriseId || '']

const extendReducersPerMapType =
  (mapInitialState: MapAnalyticsState) => (builder: ActionReducerMapBuilder<typeof byEnterpriseInitialState>) => {
    // Set map initial state per enterprise id
    builder.addCase(FETCH_ORGANIZATION_SUCCESS, fetchOrganizationSuccessReducer(mapInitialState))

    // Reset map view state when changing offices or cities filters
    const resetMapViewState = (state: typeof byEnterpriseInitialState) => {
      const currentEnterprise = getCurrentEnterprise(state)
      if (currentEnterprise) {
        currentEnterprise.viewState = mapInitialState.viewState
      }
    }
    builder.addCase(analyticFiltersActions.setSelectedCities, (state: typeof byEnterpriseInitialState) =>
      resetMapViewState(state)
    )
    builder.addCase(analyticFiltersActions.setSelectedOffices, (state: typeof byEnterpriseInitialState) =>
      resetMapViewState(state)
    )
    builder.addCase(analyticFiltersActions.resetSelectedOffices, (state: typeof byEnterpriseInitialState) =>
      resetMapViewState(state)
    )
    builder.addCase(analyticFiltersActions.resetCities, (state: typeof byEnterpriseInitialState) =>
      resetMapViewState(state)
    )
    builder.addCase(analyticFiltersActions.resetAllFilters, (state: typeof byEnterpriseInitialState) =>
      resetMapViewState(state)
    )
  }

const mapAnalyticsSlice = createSlice({
  name: AvailableAnalyticStore.CurrentCommute,
  initialState: byEnterpriseInitialState,
  reducers: {
    setIsConfigPanelCollapsed(state: typeof byEnterpriseInitialState, action: PayloadAction<boolean>) {
      const currentEnterprise = getCurrentEnterprise(state)
      if (currentEnterprise) {
        currentEnterprise.isConfigPanelCollapsed = action.payload
      }
    },
    setIsLegendCollapsed(state: typeof byEnterpriseInitialState, action: PayloadAction<boolean>) {
      const currentEnterprise = getCurrentEnterprise(state)
      if (currentEnterprise) {
        currentEnterprise.isLegendCollapsed = action.payload
      }
    },
    setMapType(state: typeof byEnterpriseInitialState, action: PayloadAction<MapType>) {
      const currentEnterprise = getCurrentEnterprise(state)
      if (currentEnterprise) {
        currentEnterprise.mapType = action.payload
        if (action.payload === MapType.Density && currentEnterprise.mapSelectedIndicator === MapMetrics.CommuteMode) {
          // Density map does not support CommuteMode metric
          currentEnterprise.mapSelectedIndicator = MapMetrics.CommuteScore
        }
      }
    },
    setMapSelectedIndicator(state: typeof byEnterpriseInitialState, action: PayloadAction<MapMetrics>) {
      const currentEnterprise = getCurrentEnterprise(state)
      if (currentEnterprise) {
        currentEnterprise.mapSelectedIndicator = action.payload
      }
    },
    setMapViewState(state: typeof byEnterpriseInitialState, action: PayloadAction<ViewStateType | undefined>) {
      const currentEnterprise = getCurrentEnterprise(state)
      if (currentEnterprise) {
        currentEnterprise.viewState = {
          ...(state.byId[state.enterpriseId || '']?.viewState || baseDefaultState.viewState),
          ...action.payload
        }
      }
    },
    setAdoptionRate(state: typeof byEnterpriseInitialState, action: PayloadAction<number>) {
      const currentEnterprise = getCurrentEnterprise(state)
      if (currentEnterprise) {
        currentEnterprise.adoptionRatePerModelingOption = {
          ...currentEnterprise.adoptionRatePerModelingOption,
          [currentEnterprise.mapSelectedIndicator as ModelingOptionValue]: action.payload
        }
      }
    },
    setIs3DMode(state: typeof byEnterpriseInitialState, action: PayloadAction<boolean>) {
      const currentEnterprise = getCurrentEnterprise(state)
      if (currentEnterprise) {
        currentEnterprise.is3DMode = action.payload
      }
    }
  },
  extraReducers: extendReducersPerMapType(initialStatePerStore[AvailableAnalyticStore.CurrentCommute])
})

const activeCommuteAnalyticsSlice = createSlice({
  name: AvailableAnalyticStore.ActiveCommuteModeling,
  initialState: byEnterpriseInitialState,
  reducers: {
    ...mapAnalyticsSlice.caseReducers,
    setMapSelectedIndicator(state: typeof byEnterpriseInitialState, action: PayloadAction<ModelingOptionValue>) {
      const currentEnterprise = getCurrentEnterprise(state)
      if (currentEnterprise) {
        currentEnterprise.mapSelectedIndicator = action.payload
      }
    }
  },
  extraReducers: extendReducersPerMapType(initialStatePerStore[AvailableAnalyticStore.ActiveCommuteModeling])
})
const transitAnalyticsSlice = createSlice({
  name: AvailableAnalyticStore.TransitModeling,
  initialState: byEnterpriseInitialState,
  reducers: {
    ...mapAnalyticsSlice.caseReducers,
    setMapSelectedIndicator(state: typeof byEnterpriseInitialState, action: PayloadAction<ModelingOptionValue>) {
      const currentEnterprise = getCurrentEnterprise(state)
      if (currentEnterprise) {
        currentEnterprise.mapSelectedIndicator = action.payload
      }
    }
  },
  extraReducers: extendReducersPerMapType(initialStatePerStore[AvailableAnalyticStore.ActiveCommuteModeling])
})

export const mapAnalyticsActions = mapAnalyticsSlice.actions
export const activeCommuteAnalyticsActions = activeCommuteAnalyticsSlice.actions
export const transitAnalyticsActions = transitAnalyticsSlice.actions

const mapReducer = combineReducers({
  [AvailableAnalyticStore.CurrentCommute]: persistReducer(
    { storage: storageSession, key: AvailableAnalyticStore.CurrentCommute },
    mapAnalyticsSlice.reducer
  ),
  [AvailableAnalyticStore.ActiveCommuteModeling]: persistReducer<ReturnType<typeof mapAnalyticsSlice.reducer>>(
    { storage: storageSession, key: AvailableAnalyticStore.ActiveCommuteModeling },
    activeCommuteAnalyticsSlice.reducer
  ),
  [AvailableAnalyticStore.TransitModeling]: persistReducer<ReturnType<typeof mapAnalyticsSlice.reducer>>(
    { storage: storageSession, key: AvailableAnalyticStore.TransitModeling },
    transitAnalyticsSlice.reducer
  )
})
export { mapReducer }
