import { CommutifiIconDefinition } from '../types'

export const faLongArrowRight: CommutifiIconDefinition = {
  prefix: 'far',
  iconName: 'long-arrow-right',
  icon: [
    512,
    512,
    [],
    '56',
    'M 291.230469 34.21875 C 287.851562 34.960938 285.863281 36.550781 274.484375 47.707031 C 263.15625 58.867188 258.484375 64.074219 257.539062 66.601562 C 256.597656 69.179688 256.746094 75.777344 257.839844 78.15625 C 259.179688 81.132812 281.789062 104.144531 344.496094 166.433594 L 399.503906 221.035156 L 207.15625 221.332031 L 14.855469 221.628906 L 11.925781 223.414062 C 8.199219 225.695312 5.664062 228.621094 4.671875 231.84375 C 3.675781 235.019531 3.179688 258.675781 3.875 268.144531 C 4.273438 273.75 4.523438 274.988281 5.613281 276.875 C 7.15625 279.503906 10.136719 282.230469 13.019531 283.769531 L 15.15625 284.859375 L 206.808594 285.15625 C 312.199219 285.304688 398.558594 285.554688 398.757812 285.703125 C 398.90625 285.898438 376.84375 308.019531 349.714844 334.945312 C 287.851562 396.292969 259.03125 425.503906 257.839844 428.230469 C 257.191406 429.667969 256.894531 431.402344 256.894531 434.429688 C 256.894531 441.324219 257.492188 442.164062 270.460938 455.011719 C 276.570312 461.011719 283.128906 467.210938 285.117188 468.847656 C 288.445312 471.523438 288.945312 471.773438 292.324219 472.269531 C 294.957031 472.617188 296.644531 472.617188 298.832031 472.121094 C 301.417969 471.574219 302.210938 471.078125 306.234375 467.507812 C 315.875 458.878906 477.960938 297.851562 494.113281 280.839844 C 501.019531 273.652344 503.007812 271.171875 504.25 268.542969 L 505.839844 265.269531 L 505.839844 240.96875 L 504.347656 238.042969 C 503.503906 236.40625 501.714844 233.777344 500.273438 232.191406 C 493.265625 224.40625 456.25 187.210938 383.949219 115.402344 C 321.09375 53.015625 303.652344 35.953125 301.914062 35.160156 C 299.28125 34.019531 294.3125 33.574219 291.230469 34.21875 Z M 291.230469 34.21875 '
  ]
}
