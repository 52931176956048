import { FeatureFlagResponse } from '@commutifi/models/FeatureFlag'
import { BuildingProps } from '@commutifi/models/Buildings'
import { OfficeProps } from '@commutifi/models/Offices'
import { AVAILABLE_LANGUAGES } from '@commutifi-fe/constants'
import { CustomBranding } from '@commutifi-fe/interfaces'
import { accountActions, updateAccount } from 'store/modules/accounts'
import { ExtendedAccount } from 'shared/interfaces/Account'
import { isFeatureEnabled as isFeatureEnabledRoot } from 'utils/helpers/featureFlags'
import { createContext, useContext } from 'react'

export interface AccountContext {
  id: string
  lat: number | null | undefined
  lng: number | null | undefined
  currentAccount: ExtendedAccount
  currentLocaleConfig: (typeof AVAILABLE_LANGUAGES)[number] | undefined
  firstName: string | undefined
  lastName: string | null | undefined
  refreshAccount: (
    promise?:
      | {
          resolve: {
            (): Promise<void>
            <T>(value: T | PromiseLike<T>): Promise<T>
          }
          reject: <T = never>(reason?: any) => Promise<T>
        }
      | undefined
  ) => accountActions.FetchMeAction
  office: Partial<OfficeProps & BuildingProps>
  timezone: string
  isFetching: boolean
  error: Record<string, any>
  logout: () => Record<string, any>
  featureFlagsError: any
  featureFlags: FeatureFlagResponse | null | undefined
  isFeatureEnabled: ReturnType<typeof isFeatureEnabledRoot>
  fetchFeatureFlags: (orgId: string | null) => void
  isLoadingFeatureFlags: boolean
  dispatchUpdateAccount: typeof updateAccount
  changeLocale: (locale: string) => any
  customBranding: CustomBranding | undefined
}

export const CurrentAccountContext = createContext<AccountContext | null>(null)

export const useCurrentAccount = (opts?: { shouldThrow: boolean }) => {
  const context = useContext(CurrentAccountContext)
  if (!context) {
    if (opts?.shouldThrow === false) {
      return {} as AccountContext
    }

    throw new Error('useCurrentAccount must be used within a CurrentAccountProvider')
  }
  return context
}
