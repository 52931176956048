import { ModeCategory, ModeName } from '@commutifi/modes';

export enum ChartTypes {
  Line = 'line',
  Bar = 'bar',
  Pie = 'pie',
  Statistics = 'statistics',
  Sunburst = 'sunburst',
}

export enum ModelingPotentialMode {
  Bike = ModeName.Bike,
  Walk = ModeName.Walk,
  EBiking = 'e-biking',
}
export enum ModelingPotentialModeCategory {
  Bike = ModeCategory.Bike,
  Walk = ModeCategory.Walk,
  PublicTransit = ModeCategory.PublicTransit,
}

export const ModelingOption = {
  ...ModelingPotentialModeCategory,
  ...ModelingPotentialMode,
};
export type ModelingOptionValue =
  (typeof ModelingOption)[keyof typeof ModelingOption];
export type ModelingOptionKey = keyof typeof ModelingOption;

export enum ModelingModeShiftPotential {
  CurrentUser = 'current_user',
  NoPotential = 'no_potential',
  High = 'high_potential',
  Low = 'low_potential',
  VeryLow = 'very_low_potential',
}

export const ANALYTICS_FLOAT_PRECISION = 4;
