import { PlanBillingMethod } from '@commutifi/models/Plan'
import slugify from 'slugify'
import { captureError } from 'utils/helpers/errors'

export function delay<T>(ms: number, res: T) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(res)
    }, ms)
  })
}

export function slugifyString(string: string): string {
  return slugify(string, {
    replacement: '_',
    remove: undefined,
    lower: true,
    trim: true
  })
}

/**
 * This function is used to validate we don't display invalid plan pricing with taxes. It will
 * silently fail on the UI but we will record a Sentry error. This is to avoid displaying
 * invalid plans costs that users will pay on OUR PLATFORM (thus the check for PlanBillingMethod = STRIPE)
 */
export const captureIsPlanValid = (plan: Record<string, any> | undefined, extra?: Record<string, any>) => {
  if (plan?.billingMethod === PlanBillingMethod.STRIPE && !('pricing' in plan)) {
    captureError(new Error('Pricing object is required on enterprise plans'), {
      extra: { plan, endpoint: extra }
    })
    return false
  }
  return true
}
