import { memoize } from 'lodash'
import polyline from '@mapbox/polyline'
import { ModeName, Stop } from '@commutifi/modes'
import { FitBounds, MapboxGLMapInstance } from '@commutifi-fe/map'
import { RouteLeg, LegType } from 'shared/interfaces/Commute'
import { hasNext } from 'utils/helpers'
import { getArrayValue } from '.'

declare type lat = number
declare type lng = number
export type coordinateType = [lng, lat]

export interface MapDrawingSection {
  coordinates: coordinateType[]
  displayColor?: string
  displayName?: string | null
  type: LegType
  mode?: ModeName | Stop
}

export const shouldDisplayEndMarker = (currentIndex: number, array: (RouteLeg | MapDrawingSection)[]) =>
  !hasNext(currentIndex, array) || (hasNext(currentIndex, array) && array[currentIndex + 1].type !== 'stop')

const getBoundsArray = (mapBounds: mapboxgl.LngLatBounds | null): FitBounds | undefined =>
  mapBounds
    ? [
        [mapBounds.getSouthWest().lng, mapBounds.getSouthWest().lat],
        [mapBounds.getNorthEast().lng, mapBounds.getNorthEast().lat]
      ]
    : undefined

export const calculateCoordinatesBounds = (
  coordinates: coordinateType[] | undefined,
  map?: MapboxGLMapInstance
): FitBounds | undefined => {
  if (!coordinates || coordinates.length === 0) {
    return map ? getBoundsArray(map.getBounds()) : undefined
  }
  const startLocation = coordinates[0]

  const bounds = coordinates.reduce(
    (bounds, coord) => bounds.extend(coord),
    new window.mapboxgl.LngLatBounds(startLocation, startLocation)
  )

  return getBoundsArray(bounds)
}

/**
 *  This function will return the map drawing options needed to display the overview polyline of
 *  each leg of a route
 *
 *  More details can be added lated if we wish to display more information in the future
 *  @param legs - {@link RouteLeg} legs of a commute (EXCLUDING stops)
 */
export const getMapDrawingSections = memoize((legs: RouteLeg | RouteLeg[]): MapDrawingSection[] => {
  const legsArray = getArrayValue(legs)
  if (legsArray.length === 0) return []

  const sections: MapDrawingSection[] = legsArray.map((leg) => {
    const legStartCoordinates: coordinateType | undefined = leg.startLocation
      ? [leg.startLocation.lng, leg.startLocation.lat]
      : undefined
    return {
      displayColor: leg.displayColor,
      displayName: leg.name,
      type: leg.type as LegType,
      mode: leg.mode || leg.activity,
      coordinates: leg.polyline
        ? (polyline.toGeoJSON(leg.polyline).coordinates as coordinateType[])
        : // When it is a stop we have only one point
          legStartCoordinates
          ? [legStartCoordinates, legStartCoordinates]
          : []
    }
  })

  return sections
})
