import { isNil } from 'lodash'
import { DEFAULT_UNITS_BY_MEASURE_TYPE, LocalesType, MeasureType, MeasureTypes } from '@commutifi-fe/constants'
import { convertToUnit, getLocaleMeasurementUnits, unit } from '@commutifi-fe/utils'
import { YEARLY_CARBON_PER_TREE_IN_KG } from '@commutifi/constants/Metrics'
import { MeasureTypeByFactor } from '@commutifi-fe/constants/metrics'
import { getCurrencyFromLocale } from '@commutifi/constants/Locale'
import { CurrencyCode } from 'constants/settings'
import { IntlShape } from 'locales/index'

import { getCountForPlural, getDaysPerYear, getMonthsPerYear, getWeeksPerYear } from './index'
import { captureError } from './errors'
import { centsToDollars } from '..'

const KG_CARBON_PER_LITER = 2.3485

/**
 * Return object with value and unit representing the equivalent time passed
 * commuting in a year (days in year) * (time in sec per week) / (time in sec per week)
 * @param duration - commute time in sec
 * @param weeklyFrequency - commute frequency per week
 */
export const calculateCommuteDaysPerYear = (duration: number, weeklyFrequency: number) => {
  const yearlyTimeCommuting = duration * weeklyFrequency * getWeeksPerYear()
  const outputUnit = 'day'
  return {
    value: convertToUnit(`${yearlyTimeCommuting} ${DEFAULT_UNITS_BY_MEASURE_TYPE.duration}`, outputUnit),
    unit: outputUnit
  }
}

/**
 * Return object with value and unit representing the equivalent of
 * liters of gas for a carbon emission
 * @param carbon - carbon emission in kg
 * @param outputUnit - Units in which the carbon value should be returned
 */
export const calculateCommuteGasPerYear = (carbon: number, outputUnit: convert.Unit) => {
  const literPerCarbon = (carbon / KG_CARBON_PER_LITER) * getMonthsPerYear()
  return literPerCarbon === 0
    ? { value: 0, unit: outputUnit }
    : {
        value: convertToUnit(`${literPerCarbon} ${DEFAULT_UNITS_BY_MEASURE_TYPE.volume}`, outputUnit),
        unit: outputUnit
      }
}

/**
 * Return INT (not float) of trees needed to be planted per year so we can offset
 * all the carbon input. We return an integer because we round up the number of trees
 * so we make sure to cover at least 100% of the carbon emissions
 * @param carbon - carbon emission in kg PER DAY
 */
export const calculateTreesCarbonOffsetPerYear = (dailyCarbonAvg: number) => {
  const yearlyCarbonInKg = dailyCarbonAvg * getDaysPerYear()
  return Math.abs(Math.ceil(yearlyCarbonInKg / YEARLY_CARBON_PER_TREE_IN_KG))
}

export const renderCommuteCostHint = (
  intl: IntlShape,
  dailyCostAvg?: number | string,
  currency?: `${CurrencyCode}`
) => {
  if (isNil(dailyCostAvg)) {
    return intl.formatMessage({ id: 'dashboard.metrics.error.hint' })
  }

  if (dailyCostAvg === 0) {
    return intl.formatMessage({ id: 'dashboard.metrics.perfection.hint' })
  }

  return intl.formatMessage(
    { id: 'dashboard.metrics.cost.yearly.hint' },
    {
      amount: intl.formatNumber(
        (typeof dailyCostAvg === 'string' ? parseFloat(dailyCostAvg) : dailyCostAvg) * getDaysPerYear(),
        {
          style: 'currency',
          currency: currency || 'USD',
          maximumSignificantDigits: 4
        }
      )
    }
  )
}

export const renderCommuteDurationHint = (
  intl: IntlShape,
  dailyDurationAvg?: number | string,
  commuteFrequency?: number
) => {
  if (isNil(dailyDurationAvg)) {
    return intl.formatMessage({ id: 'dashboard.metrics.error.hint' })
  }

  if (dailyDurationAvg === 0) {
    return intl.formatMessage({ id: 'dashboard.metrics.perfection.hint' })
  }

  const convertedDuration = calculateCommuteDaysPerYear(
    typeof dailyDurationAvg === 'string' ? parseFloat(dailyDurationAvg) : dailyDurationAvg,
    commuteFrequency ? commuteFrequency / 2 : 5
  )
  return intl.formatMessage(
    { id: 'dashboard.metrics.duration.hint' },
    {
      duration: convertedDuration.value
        ? intl.formatNumber(Math.round(convertedDuration.value))
        : intl.formatMessage({ id: 'label.status.notAvailable' }),
      unit: intl.formatMessage(
        { id: 'global.units.day' },
        { count: intl.formatNumber(convertedDuration.value ? getCountForPlural(convertedDuration.value) : 0) }
      )
    }
  )
}

export const renderCommuteCarbonHint = (intl: IntlShape, dailyCarbonAvg?: number | string) => {
  if (isNil(dailyCarbonAvg)) {
    return intl.formatMessage({ id: 'dashboard.metrics.error.hint' })
  }

  if (dailyCarbonAvg === 0) {
    return intl.formatMessage({ id: 'dashboard.metrics.perfection.hint' })
  }

  const carbonMetric = typeof dailyCarbonAvg === 'string' ? parseFloat(dailyCarbonAvg) : dailyCarbonAvg
  const treesNeededToOffset = calculateTreesCarbonOffsetPerYear(carbonMetric)
  return intl.formatMessage(
    { id: 'dashboard.metrics.carbon.hint' },
    {
      quantity: intl.formatNumber(treesNeededToOffset),
      unit: intl.formatMessage({ id: 'global.plurals.tree' }, { count: treesNeededToOffset })
    }
  )
}

/**
 * Calculate difference between src value and comparator value in %
 * @param src - value to compare with
 * @param comparator - reference value for comparison
 */
export const percentageDifference = (src: number, comparator: number) => {
  if (comparator === 0) return 0
  return Math.round(((src - comparator) / comparator) * 100)
}

/**
 * Returns the subsidized price without going under 0. If there is no subsidy
 * return null so we do not display a subsidized price that is equal to the original price.
 *
 * To apply the subsidies we need to have a positive price, else the user would 'earn' money
 *
 * NOTE: Because of legacy reasons we expect the unitPrice in dollars but the subsidy in cents
 *
 * @param unitPrice - Amount before subsidy in dollars
 * @param subsidy - Subsidy to apply to the initial amount in cents
 * @param quantity - Amount of plans/product to multiply by the unitPrice
 */
export const applySubsidy = (unitPrice: number | undefined, subsidy: number | undefined, quantity = 1) =>
  subsidy && unitPrice && unitPrice > 0 && quantity
    ? Math.max(0, unitPrice * quantity + (centsToDollars(subsidy) || 0))
    : null

export const localizeFactorValue = (value: number, factor: 'carbon' | 'cost' | 'time', locale: LocalesType) => {
  const measureType: MeasureType = MeasureTypeByFactor[factor]
  const fromUnit = DEFAULT_UNITS_BY_MEASURE_TYPE[measureType]

  const localeUnitsByMeasureType = getLocaleMeasurementUnits(locale)
  const toUnit = localeUnitsByMeasureType[measureType]

  return { value: convertToUnit(`${value} ${fromUnit}`, toUnit), unit: toUnit }
}

export const localizeCurrency = (
  value: number,
  locale: LocalesType,
  intl: IntlShape,
  options = { maximumSignificantDigits: 6 }
) => {
  if (!value) {
    return
  }
  const currency = getCurrencyFromLocale(locale)
  return intl.formatNumber(value, {
    style: 'currency',
    currency,
    // Avoid to display 1.0. This will display only significant digits (max 6)
    maximumSignificantDigits: options.maximumSignificantDigits
  })
}

/**
 * This function takes a string value that includes the unit and
 * convert it to the specified measure type default unit that is
 * used on our platform (apis and more)
 *
 * @param valueWithUnit - string value that includes the unit: '5 km' or '5km' or '5 kilometers', etc
 * @param measureType - type of the measure we want to convert the unit to out internal unit (ex. carbon - mass)
 * @returns Numeric value in the default unit (unit used by our apis) of the converted input
 */
export const convertToDefaultApiUnit = (valueWithUnit: string, measureType: MeasureType): number | string => {
  if (!MeasureTypes[measureType]) {
    captureError(new Error('Conversion Error: Measure type is not supported'), {
      extra: { measureType, supportedMeasureTypes: Object.keys(MeasureTypes) }
    })
  }

  const apiUnit = DEFAULT_UNITS_BY_MEASURE_TYPE[measureType]
  return measureType === MeasureTypes.Currency
    ? valueWithUnit
    : apiUnit
      ? unit(valueWithUnit).toNumber(apiUnit)
      : valueWithUnit
}
