import { MeasureTypes } from '@commutifi-fe/constants'
import { Location } from '@commutifi-fe/interfaces'
import { create, unitDependencies, evaluateDependencies } from 'mathjs'
import { Required } from 'utility-types'

export const getDistanceFromLatLngInKm = (
  originLocation: Location | undefined,
  destLocation: Location | undefined
) => {
  if (!originLocation || !destLocation) {
    return 0
  }

  const R = 6371 // Radius of the earth in km
  const dLat = deg2rad(destLocation.lat - originLocation.lat)
  const dLng = deg2rad(destLocation.lng - originLocation.lng)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(originLocation.lat)) *
      Math.cos(deg2rad(destLocation.lat)) *
      Math.sin(dLng / 2) *
      Math.sin(dLng / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = R * c // Distance in km
  return d
}

export const deg2rad = (deg: number) => {
  return deg * (Math.PI / 180)
}

export const limitNumberWithinRange = (
  num: number | string,
  min?: number,
  max?: number
) => {
  const MIN = min || Number.MIN_VALUE
  const MAX = max || Number.MAX_VALUE
  const parsed = typeof num === 'string' ? parseInt(num) : num
  return Math.min(Math.max(parsed, MIN), MAX)
}

export const isEven = (num: number) => num % 2 === 0

export const calculatePercentage = (value: number, total: number) => {
  if (total === 0 || value === 0) {
    return 0
  }
  return (value / total) * 100
}

export const isNumber = (num: any) =>
  num !== null && !isNaN(num) && num !== undefined

export const isConsecutiveNumbers = (numbers: (number | string)[]) => {
  if (!numbers) {
    return false
  }
  if (numbers.length <= 1) {
    return true
  }
  const differenceArray = numbers.slice(1).map((number, index) => {
    const currentNb = typeof number === 'string' ? parseInt(number) : number
    const nextNb = numbers[index]
    const comparedNb = typeof nextNb === 'string' ? parseInt(nextNb) : nextNb
    return currentNb - comparedNb
  })
  return differenceArray.every((value) => value === 1)
}

/**
 * Just a tiny helper that will check if a number is defined and return
 * the value in dollars
 * @param amount amount in cents
 * @returns amount in dollars
 */
export const centsToDollars = (amount?: number | null): number | undefined => {
  if (!amount) {
    return amount ?? undefined
  }

  return parseFloat((amount / 100).toFixed(2))
}

/**
 * Just a tiny helper that will check if a number is defined and return
 * the value in cents without any decimals
 * @param amount amount in dollars
 * @returns amount in cents
 */
export const dollarsToCents = (amount?: number | null): number | undefined => {
  if (!amount) {
    return amount ?? undefined
  }

  return Math.floor(amount * 100)
}

const { unit, evaluate } = create(
  {
    unitDependencies,
    evaluateDependencies
  },
  {}
) as Required<Partial<math.MathJsInstance>, 'unit'>

export const unitBaseToCommutifiMeasureType = (
  base: string
): MeasureTypes | null => {
  switch (base) {
    case 'LENGTH':
      return MeasureTypes.Length
    case 'MASS':
      return MeasureTypes.Mass
    case 'TIME':
      return MeasureTypes.Time
    // Be careful Liquid Volume isn't a distinct value in MathJS..
    case 'VOLUME':
      return MeasureTypes.Volume
    // Measure not yet supported by Commutifi are returned as null
    case 'AMOUNT_OF_SUBSTANCE':
    case 'ANGLE':
    case 'BINARY':
    case 'CURRENT':
    case 'ELECTRIC_POTENTIAL':
    case 'ENERGY':
    case 'FORCE':
    case 'FREQUENCY':
    case 'LUMINOUS_INTENSITY':
    case 'POWER':
    case 'PRESSURE':
    case 'SURFACE':
    case 'TEMPERATURE':
    default:
      return null
  }
}

export { unit, evaluate }
