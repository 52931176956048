import cx from 'classnames'
import { ServiceType } from '@commutifi/constants/Routes'
import {
  getModeByName,
  isValidCategory,
  isValidMode,
  isValidStop,
  ModeCategory,
  ModeName,
  modesByCategory,
  Stop
} from '@commutifi/modes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandshake, faMapMarkerAlt, faParking, faSchool, faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { faCoffeeTogo } from '@fortawesome/pro-regular-svg-icons'
import React, { CSSProperties, ReactNode } from 'react'
import isNil from 'lodash/isNil'
import { CombinedModes, LEG_TYPES, ModelingPotentialMode, ModelingPotentialModeCategory } from '@commutifi-fe/constants'
import { IntlShape } from 'locales/index'
import TreesSVG from 'shared/components/icons/TreesSVG'
import type { AppAvailableCategories, CommuteTransport, RouteLeg } from 'shared/interfaces/Commute'
import type { AugmentedFontAwesomeIconProps } from '@commutifi-fe/custom-icons/fontawesome'
import { spacing } from 'constants/global'
import { getArrayValue, joinChildren } from 'utils/index'
import { ModeIcon } from '@commutifi-fe/commutifi-specific/Presentation/ModeIcon'

export { ServiceType }

export interface TitleDescriptionKey {
  title:
    | 'global.unknown'
    | 'global.categories.all'
    | 'global.categories.driveAndTransit'
    | 'global.categories.multiple'
    | 'global.categories.unknown'
    | 'global.categories.drive'
    | 'global.categories.carpool'
    | 'global.categories.bike'
    | 'global.categories.public_transit'
    | 'global.categories.remote'
    | 'global.categories.shared_ride'
    | 'global.categories.walk'
    | 'global.activities.none'
    | 'global.modes.unknown'
    | 'modes.selection.driving'
    | 'modes.selection.bus'
    | 'modes.selection.ferry'
    | 'modes.selection.other_transit'
    | 'modes.selection.remote'
    | 'modes.selection.shuttle'
    | 'modes.selection.subway'
    | 'modes.selection.train'
    | 'modes.selection.tramway'
    | 'modes.selection.vanpool'
    | 'modes.selection.carsharing'
    | 'modes.selection.ridesharing'
    | 'modes.selection.scooter'
    | 'modes.selection.sharedScooter'
    | 'modes.selection.carpool_driver'
    | 'modes.selection.carpool_passenger'
    | 'modes.selection.bicycling'
    | 'modes.selection.walking'
    | 'modes.selection.bikesharing'
  description?:
    | 'global.categories.multiple.description'
    | 'modes.selection.driving.description'
    | 'modes.selection.shuttle.description'
    | 'modes.selection.vanpool.description'
    | 'modes.selection.carsharing.description'
    | 'modes.selection.ridesharing.description'
    | 'modes.selection.scooter.description'
    | 'modes.selection.sharedScooter.description'
    | 'modes.selection.carpool_driver.description'
    | 'modes.selection.carpool_passenger.description'
    | 'modes.selection.bicycling.description'
    | 'modes.selection.bikesharing.description'
}

export const typeSafeIsValidMode = (mode: string): mode is ModeName | ModelingPotentialMode =>
  isValidModelingMode(mode) || isValidMode(mode)
export const typeSafeIsValidCategory = (category: string): category is ModeCategory => isValidCategory(category)
export const typeSafeIsValidStop = (stop: string): stop is Stop => isValidStop(stop)
export const isValidCombinedMode = (combinedMode: string): combinedMode is CombinedModes =>
  combinedMode === CombinedModes.DriveAndTransit || combinedMode === CombinedModes.MixedModes

/**
 * This function returns the translation key for a mode
 * It's not the function used in the survey selection as the modes there are a bit special
 * Use this function if you need to display a mode to the user
 *
 * @param mode- the mode to be displayed. Ideally it should be a valid mode from the modes lib,
 *                      but it can also be a special "mode" (like 'all')
 *
 */
export const getTranslationKeyForMode = (
  mode?: string | null
):
  | ReturnType<typeof getTranslationKeyForKnownMode>
  | 'global.modes.all'
  | 'global.activities.none'
  | 'global.modes.unknown' => {
  // The reason to not add a default case to getTranslationKeyForKnownMode is so we get a typescript
  // error if we ever add a new mode and don't specify the key for it

  // Some other non valid modes that we support for the sake of simplicity
  switch (mode) {
    case 'all':
      return 'global.modes.all'
    case 'none':
      return 'global.activities.none'
    default:
      if (mode && typeSafeIsValidMode(mode)) {
        return getTranslationKeyForKnownMode(mode)
      }

      return 'global.modes.unknown'
  }
}

/**
 * Return the translation key corresponding to the activity passed in parameter.
 * If the activity is not supported yet we return the key for unknown activity.
 * @param activity- Activity the user does during his commute
 *
 */
export const getTranslationKeyForStop = (
  stop?: string | Stop
):
  | 'global.activities.none'
  | 'global.activities.drop_off_kids'
  | 'global.activities.errands'
  | 'global.activities.food_drink'
  | 'global.activities.meetings'
  | 'global.activities.parking'
  | 'global.activities.physical_activity'
  | 'global.activities.extra'
  | 'global.unknown' => {
  // The reason to not add a default case to getTranslationKeyForKnownStop is so we get a typescript
  // error if we ever add a new stop and don't specify the key for it
  const lowercaseStop = (stop || '').toLowerCase()
  if (typeSafeIsValidStop(lowercaseStop)) {
    return getTranslationKeyForKnownStop(lowercaseStop)
  }

  // Some other non valid activities that we support for the sake of simplicity
  switch (lowercaseStop) {
    case 'none':
      return 'global.activities.none'
    default:
      return 'global.unknown'
  }
}

const getTranslationKeyForKnownStop = (
  stop: Stop
):
  | 'global.activities.drop_off_kids'
  | 'global.activities.errands'
  | 'global.activities.food_drink'
  | 'global.activities.meetings'
  | 'global.activities.parking'
  | 'global.activities.physical_activity'
  | 'global.activities.extra' => {
  // eslint-disable-next-line default-case -- This is on purpose to have a typescript error where we use this function
  switch (stop) {
    case Stop.DropOffKids:
      return 'global.activities.drop_off_kids'
    case Stop.Errands:
      return 'global.activities.errands'
    case Stop.FoodDrink:
      return 'global.activities.food_drink'
    case Stop.Meetings:
      return 'global.activities.meetings'
    case Stop.Parking:
      return 'global.activities.parking'
    case Stop.PhysicalActivity:
      return 'global.activities.physical_activity'
    case Stop.Other:
      return 'global.activities.extra'
  }
}

/**
 * This function returns the translation key for a category
 * Use this function if you need to display a category to the user
 *
 * @param category- the category to be displayed. Ideally it should be a valid category from the modes lib,
 *                      but it can also be a special "category" (like 'multiple', 'driveAndTransit')
 *
 */
export const getTranslationKeyForCategory = (category?: string | null): TitleDescriptionKey => {
  // The reason to not add a default case to getSurveySelectionKnownModesTranslationKeys is so we get a typescript
  // error if we ever add a new mode and don't specify the key for it

  // Some other non valid categories that we support for the sake of simplicity
  switch (category) {
    case 'all':
      return { title: 'global.categories.all' }
    case 'driveAndTransit':
    case CombinedModes.DriveAndTransit:
      return { title: 'global.categories.driveAndTransit' }
    case 'multiple':
    case CombinedModes.MixedModes:
      return { title: 'global.categories.multiple', description: 'global.categories.multiple.description' }
    default:
      if (category && typeSafeIsValidCategory(category)) {
        return getTranslationKeyForKnownCategory(category)
      }
      return { title: 'global.categories.unknown' }
  }
}

/**
 * This function returns the translation key for a mode in the survey mode selection
 * It's not the function used in the anywhere else. It's just because in the survey we have different mode names
 * Eg. Carshare becomes Carshare (eg. Car2Go and others)
 *
 * @param mode - the mode to be displayed. Ideally it should be a valid mode from the modes lib,
 *                      but it can also be a special "mode" (like 'all')
 *
 * @returns key the translation key for the mode or an unknown key if we couldn't find it
 *
 */ export const getSurveySelectionModesTranslationKeys = (mode: string): TitleDescriptionKey => {
  // The reason to not add a default case to getSurveySelectionKnownModesTranslationKeys is so we get a typescript
  // error if we ever add a new mode and don't specify the key for it
  if (isValidMode(mode)) {
    return getSurveySelectionKnownModesTranslationKeys(mode)
  }

  return { title: 'global.activities.none' }
}

const getTranslationKeyForKnownCategory = (category: ModeCategory): TitleDescriptionKey => {
  switch (category) {
    case ModeCategory.Drive:
      return { title: 'global.categories.drive' }
    case ModeCategory.Carpool:
      return { title: 'global.categories.carpool' }
    case ModeCategory.Bike:
      return { title: 'global.categories.bike' }
    case ModeCategory.PublicTransit:
      return { title: 'global.categories.public_transit' }
    case ModeCategory.Remote:
      return { title: 'global.categories.remote' }
    case ModeCategory.SharedRide:
      return { title: 'global.categories.shared_ride' }
    case ModeCategory.Walk:
      return { title: 'global.categories.walk' }
    default:
      return { title: 'global.unknown' }
  }
}

const getSurveySelectionKnownModesTranslationKeys = (mode: ModeName): TitleDescriptionKey => {
  const descriptionSuffix = 'description'
  let key: TitleDescriptionKey['title']
  switch (mode) {
    case ModeName.Drive:
      key = 'modes.selection.driving'
      return { title: key, description: `${key}.${descriptionSuffix}` }
    case ModeName.Bus:
      key = 'modes.selection.bus'
      return { title: key }
    case ModeName.Ferry:
      key = 'modes.selection.ferry'
      return { title: key }
    case ModeName.OtherTransit:
      key = 'modes.selection.other_transit'
      return { title: key }
    case ModeName.Remote:
      key = 'modes.selection.remote'
      return { title: key }
    case ModeName.Shuttle:
      key = 'modes.selection.shuttle'
      return { title: key, description: `${key}.${descriptionSuffix}` }
    case ModeName.Subway:
      key = 'modes.selection.subway'
      return { title: key }
    case ModeName.Train:
      key = 'modes.selection.train'
      return { title: key }
    case ModeName.Tramway:
      key = 'modes.selection.tramway'
      return { title: key }
    case ModeName.Vanpool:
      key = 'modes.selection.vanpool'
      return { title: key, description: `${key}.${descriptionSuffix}` }
    case ModeName.Carshare:
      key = 'modes.selection.carsharing'
      return { title: key, description: `${key}.${descriptionSuffix}` }
    case ModeName.Taxi:
    case ModeName.Rideshare:
      key = 'modes.selection.ridesharing'
      return { title: key, description: `${key}.${descriptionSuffix}` }
    case ModeName.Scooter:
      key = 'modes.selection.scooter'
      return { title: key, description: `${key}.${descriptionSuffix}` }
    case ModeName.SharedScooter:
      key = 'modes.selection.sharedScooter'
      return { title: key, description: `${key}.${descriptionSuffix}` }
    case ModeName.CarpoolDriver:
      key = 'modes.selection.carpool_driver'
      return { title: key, description: `${key}.${descriptionSuffix}` }
    case ModeName.CarpoolPassenger:
      key = 'modes.selection.carpool_passenger'
      return { title: key, description: `${key}.${descriptionSuffix}` }
    case ModeName.Bike:
      key = 'modes.selection.bicycling'
      return { title: key, description: `${key}.${descriptionSuffix}` }
    case ModeName.Walk:
      key = 'modes.selection.walking'
      return { title: key }
    case ModeName.Bikeshare:
      key = 'modes.selection.bikesharing'
      return { title: key, description: `${key}.${descriptionSuffix}` }
    default:
      return { title: 'global.unknown' }
  }
}

// Modes
const getTranslationKeyForKnownMode = (
  mode: ModeName | ModelingPotentialMode
):
  | 'global.modes.driving'
  | 'global.modes.bicycling'
  | 'global.modes.bicycling.electric'
  | 'global.modes.walking'
  | 'global.modes.bikesharing'
  | 'global.modes.bus'
  | 'modes.selection.carpool_passenger'
  | 'modes.selection.carpool_driver'
  | 'global.modes.carsharing'
  | 'global.modes.ferry'
  | 'global.modes.other_transit'
  | 'global.modes.remote'
  | 'global.modes.ridesharing'
  | 'global.modes.scooter'
  | 'global.modes.sharedScooter'
  | 'global.modes.shuttle'
  | 'global.modes.subway'
  | 'global.modes.train'
  | 'global.modes.tramway'
  | 'global.modes.vanpool'
  | 'global.modes.taxi'
  | 'global.unknown' => {
  switch (mode) {
    case ModeName.Drive:
      return 'global.modes.driving'
    case ModeName.Bike:
    case ModelingPotentialMode.Bike:
      return 'global.modes.bicycling'
    case ModelingPotentialMode.EBiking:
      return 'global.modes.bicycling.electric'
    case ModeName.Walk:
    case ModelingPotentialMode.Walk:
      return 'global.modes.walking'
    case ModeName.Bikeshare:
      return 'global.modes.bikesharing'
    case ModeName.Bus:
      return 'global.modes.bus'
    case ModeName.CarpoolPassenger:
      return 'modes.selection.carpool_passenger'
    case ModeName.CarpoolDriver:
      return 'modes.selection.carpool_driver'
    case ModeName.Carshare:
      return 'global.modes.carsharing'
    case ModeName.Ferry:
      return 'global.modes.ferry'
    case ModeName.OtherTransit:
      return 'global.modes.other_transit'
    case ModeName.Remote:
      return 'global.modes.remote'
    case ModeName.Rideshare:
      return 'global.modes.ridesharing'
    case ModeName.Scooter:
      return 'global.modes.scooter'
    case ModeName.SharedScooter:
      return 'global.modes.sharedScooter'
    case ModeName.Shuttle:
      return 'global.modes.shuttle'
    case ModeName.Subway:
      return 'global.modes.subway'
    case ModeName.Train:
      return 'global.modes.train'
    case ModeName.Tramway:
      return 'global.modes.tramway'
    case ModeName.Vanpool:
      return 'global.modes.vanpool'
    case ModeName.Taxi:
      return 'global.modes.taxi'
    default:
      return 'global.unknown'
  }
}

/**
 * This will ensure that any mode that is specific for modeling, like ebike, will be properly translated
 * @param mode - the mode to be displayed. Ideally it should be a valid mode from the modeling modes
 */
export const getTranslationKeyForModelingMode = (
  mode?: string | null
):
  | ReturnType<typeof getTranslationKeyForKnownMode>
  | 'global.modes.bicycling'
  | 'global.modes.bicycling.electric'
  | 'global.modes.walking'
  | 'global.modes.unknown' => {
  // The reason to not add a default case to getTranslationKeyForKnownMode is so we get a typescript
  // error if we ever add a new mode and don't specify the key for it

  // Some other non valid modes that we support for the sake of simplicity
  switch (mode) {
    case ModelingPotentialMode.Bike:
      return 'global.modes.bicycling'
    case ModelingPotentialMode.EBiking:
      return 'global.modes.bicycling.electric'
    case ModelingPotentialMode.Walk:
      return 'global.modes.walking'
    default:
      if (mode && typeSafeIsValidMode(mode)) {
        return getTranslationKeyForKnownMode(mode)
      }

      return 'global.modes.unknown'
  }
}

const paidModes = [
  ModeName.Bikeshare,
  ModeName.Bus,
  ModeName.CarpoolDriver,
  ModeName.CarpoolPassenger,
  ModeName.Carshare,
  ModeName.Drive,
  ModeName.Ferry,
  ModeName.OtherTransit,
  ModeName.SharedScooter,
  ModeName.Shuttle,
  ModeName.Subway,
  ModeName.Train,
  ModeName.Tramway,
  ModeName.Vanpool
]
/**
 * Checks if the mode required a payment that is not related
 * to maintenance (not an irregular cost based on dynamic factors like for cars)
 *
 * NOTES:
 * - Rideshare and taxi are calculated afterwards so we don't require the user
 *   input for those 2 modes
 * - Make sure to keep this function up to date with new modes
 *
 * @param mode - Any commutifi mode (ModeName)
 *
 * @returns true if the mode require a regular extra payment
 */
export const isPaidMode = (mode?: ModeName | null) => mode && paidModes.some((paidMode) => paidMode === mode)

const paidCategories = [ModeCategory.Drive, ModeCategory.PublicTransit]
/**
 * Similar to isPaidMode but for categories. All the modes in the category
 * must require a payment information from the user for the category to qualify
 *
 * @param category - Any commutifi category (ModeCategory)
 *
 * @returns true if the category require a regular extra payment for all it's modes
 */
export const isPaidCategory = (category?: AppAvailableCategories | null): boolean =>
  Boolean(category && paidCategories.some((paidCategory) => paidCategory === category))

export const getTranslationKeyForTransportCost = (
  transport: CommuteTransport | undefined
):
  | 'cost.questions.drive'
  | 'cost.questions.carsharing'
  | 'cost.questions.carpool_driver'
  | 'cost.questions.bikesharing'
  | 'cost.questions.public_transit'
  | 'cost.questions.carpool_passenger'
  | 'cost.questions.sharedScooter'
  | 'cost.questions.vanpool'
  | 'cost.questions.shuttle'
  | 'global.unknown' => {
  switch (!transport ? '' : transport.mode || transport.category || transport.stop) {
    case ModeCategory.Drive:
    case ModeName.Drive:
    case Stop.Parking:
      return 'cost.questions.drive'
    case ModeName.Carshare:
      return 'cost.questions.carsharing'
    case ModeName.CarpoolDriver:
      return 'cost.questions.carpool_driver'
    case ModeName.Bikeshare:
      return 'cost.questions.bikesharing'
    case ModeCategory.PublicTransit:
    case ModeName.Bus:
    case ModeName.Train:
    case ModeName.Subway:
    case ModeName.Ferry:
    case ModeName.Tramway:
    case ModeName.OtherTransit:
      return 'cost.questions.public_transit'
    case ModeName.CarpoolPassenger:
      return 'cost.questions.carpool_passenger'
    case ModeName.SharedScooter:
      return 'cost.questions.sharedScooter'
    case ModeName.Vanpool:
      return 'cost.questions.vanpool'
    case ModeName.Shuttle:
      return 'cost.questions.shuttle'
    default:
      return 'global.unknown'
  }
}

export const getServiceAttributes = (serviceType: string) => {
  switch ((serviceType || '').toLowerCase()) {
    case ServiceType.carbonOffset:
      return {
        img: TreesSVG,
        buttonTranslationKey: 'routes.services.types.carbonOffset.button.text'
      }
    default:
      return {}
  }
}

export const getServiceTypeTranslationKey = (
  serviceType: `${ServiceType}` | undefined
): 'services.types.carbon_offset' | 'services.types.mode' | 'global.activities.none' => {
  switch ((serviceType || '').toLowerCase()) {
    case ServiceType.carbonOffset:
    case ServiceType.mode:
      return `services.types.${serviceType}` as ReturnType<typeof getServiceTypeTranslationKey>
    case ServiceType.amenity:
    case ServiceType.activity:
    case ServiceType.program:
    default:
      return 'global.activities.none'
  }
}

export const isValidModelingMode = (mode: string): mode is ModelingPotentialMode =>
  Object.values(ModelingPotentialMode).includes(mode as ModelingPotentialMode)

export const getModeIntl = (mode?: string | null) => {
  const definedMode = mode || ''
  if (isValidStop(definedMode)) {
    return { id: getTranslationKeyForStop(definedMode), defaultMessage: definedMode }
  }
  if (isValidMode(definedMode)) {
    return { id: getTranslationKeyForMode(definedMode), defaultMessage: definedMode }
  }
  if (isValidModelingMode(definedMode)) {
    // Note that there is no modeling category check for the moment since all modeling categories are
    // part of our defined categories
    return { id: getTranslationKeyForModelingMode(definedMode), defaultMessage: definedMode }
  }
  if (isValidCategory(definedMode)) {
    return { id: getTranslationKeyForCategory(definedMode).title, defaultMessage: definedMode }
  }
  if (isValidCombinedMode(definedMode)) {
    return { id: getTranslationKeyForCategory(definedMode).title, defaultMessage: definedMode }
  }
  return { id: getServiceTypeTranslationKey(definedMode as ServiceType), defaultMessage: definedMode }
}

export const getModeObject = (mode?: string | null) => (intl: IntlShape) => ({
  name: intl.formatMessage(getModeIntl(mode)),
  icon: isValidStop(mode || '') ? getStopIconElement(mode as Stop) : <ModeIcon mode={mode} />
})

/**
 * Return corresponding svg (with fallback case) for a specified stop
 * @param stop - represents a stop name (gym, drop-off-kids, etc.)
 */
export const getStopIconElement = (stop?: Stop, props?: Partial<AugmentedFontAwesomeIconProps>) => {
  switch ((stop || '').toLowerCase()) {
    case Stop.DropOffKids:
      return <FontAwesomeIcon icon={faSchool} {...props} />
    case Stop.Errands:
      return <FontAwesomeIcon icon={faShoppingCart} {...props} />
    case Stop.FoodDrink:
      return <FontAwesomeIcon icon={faCoffeeTogo} {...props} />
    case Stop.Meetings:
      return <FontAwesomeIcon icon={faHandshake} {...props} />
    case Stop.Parking:
      return <FontAwesomeIcon icon={faParking} {...props} />
    case Stop.Other:
    default:
      return <FontAwesomeIcon icon={faMapMarkerAlt} {...props} />
  }
}

/**
 * Display a list of modes.
 * Note that all elements must be valid strings so we can properly join nodes
 * @param modesWithPotentialNil - Modes to display in a list with a separator that can be configured
 * @param intl - Intl object for translations
 * @param opts - Configuration options
 * @param opts.hideIcon - Hide the icon for the first mode
 * @param opts.joinNode - Node to join the modes with
 * @param opts.ellipsis - Add ellipsis to the text if it's too long
 * @param opts.multiIcon - Show the icon before each mode label
 * @param opts.iconStyle - Style for the icon
 * @param opts.style - Style for the container
 * @param opts.className - Class name for the container
 *
 * @returns A list of modes with the first mode having an icon (and potentially the rest)
 */
export const displayModes = (
  modesWithPotentialNil:
    | (
        | ModeName
        | Stop
        | ModeCategory
        | CombinedModes
        | ModelingPotentialMode
        | ModelingPotentialModeCategory
        | undefined
        | null
      )[]
    | undefined,
  intl: IntlShape,
  opts?: {
    hideIcon?: boolean
    joinNode?: string | ReactNode
    ellipsis?: boolean
    multiIcon?: boolean
    iconStyle?: CSSProperties
    className?: string
    style?: CSSProperties
  }
) => {
  const modes = modesWithPotentialNil?.filter(
    (
      mode
    ): mode is ModeName | Stop | ModeCategory | CombinedModes | ModelingPotentialMode | ModelingPotentialModeCategory =>
      !!mode
  )
  const options = { ellipsis: true, ...opts }
  if (!modes || modes.length === 0) {
    return
  }

  // For now we display only the 3 first modes, this could eventually be passed as an option
  const modesTranslated = modes.slice(0, 3).map((mode) => intl.formatMessage(getModeIntl(mode)))
  return (
    <span
      className={cx('ant-typography app-typography app-typography--regular u-flex-align-center', options.className)}
      style={
        options.ellipsis
          ? {
              marginBottom: 0,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              ...options.style
            }
          : options.style
      }
      aria-label={modesTranslated.join(', ')}
    >
      {joinChildren(
        modesTranslated,
        (mode: string, index: number) =>
          options.multiIcon || index === 0 ? (
            <React.Fragment key={`${mode}-${index}`}>
              {options.hideIcon ? null : (
                <span
                  style={{
                    marginRight: spacing.small2,
                    ...options.iconStyle
                  }}
                >
                  <ModeIcon mode={modes[index]} />
                </span>
              )}
              {mode}
            </React.Fragment>
          ) : (
            mode
          ),
        (index: number) =>
          options.joinNode ? (
            typeof options.joinNode === 'string' ? (
              ` ${options.joinNode} `
            ) : (
              <span key={index}>&nbsp;{options.joinNode}&nbsp;</span>
            )
          ) : index === modesTranslated.length - 2 ? (
            ` ${intl.formatMessage({ id: 'global.and' })} `
          ) : (
            ', '
          )
      )}
    </span>
  )
}

export const inferModeFromLegs = (leg: Partial<RouteLeg>[] | Partial<RouteLeg> | undefined) => {
  const legs = getArrayValue(leg).map((l) =>
    l.mode && !l.category ? { ...l, category: getModeByName(l.mode).category } : l
  )
  if (legs.length === 0) return { category: ModeCategory.None, mode: ModeName.None }
  // If there is one leg we should take that mode
  // Also if all the legs that are not stops have the same mode
  // we should pick that mode to represent the route as the general mode
  if (
    legs.length === 1 ||
    legs
      .filter((leg) => !leg.type || leg.type === LEG_TYPES.leg)
      .every((leg, index, legs) => index === 0 || leg.mode === legs[index - 1].mode)
  ) {
    const leg = legs[0]
    return {
      category: !isNil(leg.category) ? leg.category : undefined,
      mode: leg.mode
    }
  }
  if (legs.length > 1 && legs[0].category === ModeCategory.Drive && legs[1].category === ModeCategory.PublicTransit) {
    return { category: CombinedModes.DriveAndTransit, mode: undefined }
  }
  return legs.some((leg) => leg.mode && leg.category === ModeCategory.PublicTransit && !isNil(leg.mode))
    ? { category: ModeCategory.PublicTransit, mode: undefined }
    : { category: CombinedModes.MixedModes, mode: undefined }
}

/**
 * Validate if we should display the mode when the information about the
 * category is already descriptive enough. This can happen when there is only
 * one mode for a specific category
 * @example We don't want to end up with something like walk - walk
 *
 * @param category - One of commutifi's category (ModeCategory enum)
 *                  OR a combined mode (drive + transit | multi modes)
 * @param mode - One of commutifi's mode (ModeName enum)
 *
 * @returns true when the mode adds information on the category
 */
export const shouldDisplayMode = (category?: AppAvailableCategories | null, mode?: ModeName | null) =>
  category &&
  mode &&
  (category === CombinedModes.MixedModes ||
    category === CombinedModes.DriveAndTransit ||
    modesByCategory[category].length > 1)

export const isCarpool = (leg: RouteLeg | CommuteTransport) =>
  leg.category === ModeCategory.Carpool || (leg.mode && getModeByName(leg.mode).category === ModeCategory.Carpool)
export const isDriver = (leg: RouteLeg | CommuteTransport) =>
  leg.category === ModeCategory.Drive || leg.mode === ModeName.CarpoolDriver || leg.mode === ModeName.Drive
export const isRemote = (leg: RouteLeg | CommuteTransport) =>
  leg.category === ModeCategory.Remote || (leg.mode && getModeByName(leg.mode).category === ModeCategory.Remote)
export const isTransit = (leg: RouteLeg | CommuteTransport) =>
  leg.category === ModeCategory.PublicTransit ||
  (leg.mode && getModeByName(leg.mode).category === ModeCategory.PublicTransit)

export const getTranslationKeyFromTransport = (transport: CommuteTransport) =>
  typeSafeIsValidMode(transport.mode || '')
    ? getTranslationKeyForMode(transport.mode || '')
    : getTranslationKeyForCategory(transport.category).title
