import { PlanBookingType, PlanFrequency } from '@commutifi/models/Plan'
import { useComponentIntl } from '../../locales'

/**
 * Return the cost frequency suffix/prefix based on specs:
 * 
 * If plan has canBuyMany and frequency = monthly
 *   display: each per month
 * If plan.costType is free
 *   display: Free
 * If plan.costType is variable
 *   display: Variable
 * if plan.costType is singular
 *   3.1. If bookingType is recurrent
 *   3.1.1. frequency yearly: $x per year
 *   3.1.2. frequency monthly: $x per month
 *   3.2. If bookingType is fixed/singular
 *   3.2.1. frequency daily: $x per day
 *   3.2.2. frequency per trip: $x per trip
 *   3.3. If bookingType is standard or unique
 *     display: $x

 * @param cost - cost object
 * @param options - options object
 * @param   bookingType - booking type
 * @param   canBuyMany - can buy many plans (quantity available)
 * @returns 
 */
export function FrequencyDisplay({ frequency, bookingType, canBuyMany }: CostFrequencyProps) {
  const intl = useComponentIntl('FrequencyDisplay')
  const perUnitText = canBuyMany ? <>{intl.formatMessage({ id: 'cost.types.each' })} </> : ''

  const costFrequencyMapping = {
    [PlanFrequency.PER_TRIP]: intl.formatMessage({ id: 'cost.frequencies.perTrip' }),
    [PlanFrequency.DAILY]: intl.formatMessage({ id: 'cost.frequencies.perDay' }),
    [PlanFrequency.MONTHLY]: intl.formatMessage({ id: 'cost.frequencies.perMonth' }),
    [PlanFrequency.YEARLY]: intl.formatMessage({ id: 'cost.frequencies.perYear' })
  }
  const costFrequency = frequency && costFrequencyMapping[frequency] ? costFrequencyMapping[frequency] : undefined
  switch (bookingType || '') {
    case PlanBookingType.STANDARD:
    case PlanBookingType.UNIQUE:
      return intl.formatMessage({ id: 'cost.frequencies.unique' })
    case PlanBookingType.RECURRENT:
      return (
        <>
          {perUnitText}
          {costFrequency}
        </>
      )
    case PlanBookingType.SINGULAR:
      return (
        <>
          {perUnitText}
          {costFrequency}
        </>
      )
    default:
      return (
        <>
          {perUnitText}
          {costFrequency}
        </>
      )
  }
}

interface CostFrequencyProps {
  frequency: PlanFrequency | null | undefined
  bookingType?: PlanBookingType | null
  canBuyMany?: boolean
}
