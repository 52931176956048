import { PlansSuggestionsRepositoryImpl } from '@commutifi-specific/Data/Repositories/CommuterPlans'
import { Account, GetResponse, RequestParams } from '@commutifi-fe/interfaces'
import { PlanThirdPartyIntegration } from '@commutifi/models/Plan'
import { DaysValueEnum, RegisterFormPayload } from 'shared/interfaces'
import { AccountWalletProps } from '@commutifi/models/AccountsWallets'
import { WalletEntryProps } from '@commutifi/models/WalletsEntries'
import { ModeName } from '@commutifi/modes'
import { apiCall, AxiosHttpInstance, dashboardApiServer, JSON_HEADER } from '../../index'
import { FetchAccountVehicleResponse, accountVehicleDefaultFields, accountVehicleDefaultRelations } from '../vehicles'
import { ListPlan, PlanBookingType, ServiceCategory, ServiceMode } from '../commuterPlans'
import { PaymentMethod, PostPaymentMethodBody } from '../paymentMethods'
import { captureIsPlanValid } from '../../utils/global'
import { CardProps } from '../commutifiCards'
import { BookingProps } from '../bookings'
import { WalletBalance } from '../wallets'
import endpoints from './endpoints'

export const apiDashboardServer = apiCall(dashboardApiServer)

export const fetchMe = (): Promise<Account> =>
  apiDashboardServer('authentication/me', {
    method: 'get',
    headers: JSON_HEADER
  })

export const fetchAccount = (id: string, queryParams: any): Promise<Account> =>
  apiDashboardServer(endpoints.GET.Account.route(id), {
    method: 'get',
    params: queryParams
  })

export const fetchAccountSubscriptions = (id: string) =>
  apiDashboardServer(endpoints.GET.SubscriptionsLegacy.route(id), {
    method: 'get'
  })

export const batchUpdateAccounts = (accountIds: string[], patch: Partial<Account>): Promise<Account[]> =>
  apiDashboardServer(endpoints.PATCH.BatchAccounts.route(), {
    method: 'patch',
    headers: JSON_HEADER,
    data: JSON.stringify({ ids: accountIds, data: patch })
  })

export const updateOneAccount = (id: string | undefined, patch: Partial<Account>) => {
  if (!id) {
    throw new Error('Missing account id to update account')
  }
  return apiDashboardServer(endpoints.PATCH.Account.route(id), {
    method: 'patch',
    headers: JSON_HEADER,
    data: JSON.stringify(patch)
  })
}

export interface AccountCommuteData {
  arriveTime: string
  carbon: number
  commuteDays: DaysValueEnum[]
  commuteModes: ModeName[]
  cost: number
  distance: number
  duration: number
  leaveTime: string
  score: { global: number }
}

export const fetchAccountCommuteData = (id: string): Promise<AccountCommuteData> =>
  apiDashboardServer(endpoints.GET.CommuteData.route(id), {
    method: 'get'
  })

export const patchAccountPassword = ({
  id,
  data
}: {
  id: string
  data: { oldPassword: string; newPassword: string }
}) =>
  apiDashboardServer(endpoints.PATCH.AccountPassword.route(id), {
    method: 'patch',
    headers: JSON_HEADER,
    data: JSON.stringify(data)
  })

export const addGroupToAccount = (groupId: string, accountId: string, queryParams: any = {}) =>
  apiDashboardServer(endpoints.POST.AccountGroup.route(accountId, groupId), {
    method: 'post',
    params: queryParams,
    headers: JSON_HEADER
  })

export const createEnterpriseSurveyAccount = (data: RegisterFormPayload & { enterpriseSurveyShortlink: string }) =>
  apiDashboardServer(endpoints.POST.Accounts.route(), {
    method: 'post',
    headers: JSON_HEADER,
    data
  })

export const postEmailVerificationEmail = (data: { accountId?: string; email?: string }) =>
  apiDashboardServer(endpoints.POST.SendEmailToVerifyAccount.route(), {
    method: 'post',
    data
  })

export const postEmailVerification = (accountId: string, data: { emailVerificationToken: string }) =>
  apiDashboardServer(endpoints.POST.VerifyEmail.route(accountId), {
    method: 'post',
    data
  })

export type VerifyPasswordResponse =
  | {
      passwordNeeded: true
      email: string
      name: string
    }
  | {
      passwordNeeded: false
      email: undefined
      name: undefined
    }

export const verifyPasswordNeeded = (accountId: string): Promise<VerifyPasswordResponse> =>
  apiDashboardServer(endpoints.GET.VerifyPasswordNeeded.route(accountId), {
    method: 'get',
    headers: JSON_HEADER
  }).then((res) => res?.data)

export interface PlanQueryParams extends RequestParams {
  id?: string | string[]
  enterpriseId?: string
  mostPopular?: boolean
}

export const fetchPlans = async (accountId: string, params: PlanQueryParams): Promise<GetResponse<ListPlan>> => {
  const res = await apiDashboardServer<Awaited<ReturnType<typeof fetchPlans>>>(endpoints.GET.Plans.route(accountId), {
    method: 'get',
    params
  })

  res.records.forEach((plan) => captureIsPlanValid(plan, { endpoint: endpoints.GET.Plans.route(accountId), params }))
  return res
}

export interface PlanSuggestionsQueryParams extends RequestParams {
  'ne:id'?: string
  enterpriseId?: string
  serviceId?: string
  providerId?: string
  modes?: ServiceMode[]
  categories?: ServiceCategory[]
}

export const fetchPlansSuggestions = (
  accountId: string,
  params: PlanSuggestionsQueryParams
): Promise<GetResponse<ListPlan>> =>
  apiDashboardServer(endpoints.GET.PlansSuggestions.route(accountId), {
    method: 'get',
    params
  })

export interface AccountBookingQueryParams extends RequestParams {
  sort?: 'recurrentRelevance' | 'nonRecurrentRelevance'
  bookingType?: PlanBookingType
  'ne:bookingType'?: PlanBookingType
  'plan.thirdPartyIntegration'?: PlanThirdPartyIntegration
  'ne:null:externalBookingId'?: 1
}
export function fetchAccountBookings(
  accountId: string,
  queryParams: AccountBookingQueryParams
): Promise<GetResponse<BookingProps>> {
  return apiDashboardServer(endpoints.GET.AccountBookings.route(accountId), {
    method: 'get',
    queryParams
  })
}

export const fetchAccountWalletEnrolments = (
  accountId: string,
  queryParams: RequestParams & { balance?: boolean } & Partial<
      Record<keyof AccountWalletProps, string | string[] | number>
    >
): Promise<GetResponse<AccountWalletProps & WalletBalance>> =>
  apiDashboardServer(endpoints.GET.AccountWalletEnrollments.route(accountId), {
    method: 'get',
    queryParams
  })

export const fetchAccountWalletEntries = async (
  accountId: string,
  queryParams: RequestParams
): Promise<GetResponse<WalletEntryProps>> =>
  apiDashboardServer(endpoints.GET.AccountWalletEntries.route(accountId), {
    method: 'get',
    queryParams
  })

export const fetchAccountCards = (accountId: string, queryParams: RequestParams): Promise<GetResponse<CardProps>> =>
  apiDashboardServer(endpoints.GET.AccountCards.route(accountId), {
    method: 'get',
    queryParams
  })

export interface PatchAccountsGroupsBody {
  accountIds: string[] | undefined
  operation: 'assign' | 'unassign' | undefined
  groupId: string
}

export const patchAccountsGroups = (
  enterpriseId: string,
  body: PatchAccountsGroupsBody[]
): Promise<{ records: PatchAccountsGroupsBody[] }> =>
  apiDashboardServer(endpoints.PATCH.AccountsGroups.route(), {
    method: 'patch',
    headers: JSON_HEADER,
    queryParams: { enterpriseId },
    data: JSON.stringify(body)
  })

export const makeAccountPlansSuggestionsRepository = (accountId: string) =>
  PlansSuggestionsRepositoryImpl<PlanSuggestionsQueryParams>(
    endpoints.GET.PlansSuggestions.route(accountId),
    AxiosHttpInstance
  )

interface AccountKCIResponse {
  scoreVariation?: { value: number; since: string }
  scores: {
    carbon: number
    cost: number
    time: number
    global: number
  }
  metrics: {
    time: { perDay: number }
    carbon: { perDay: number }
    cost: { perDay: number }
  }
}

interface AccountRegionMetricsResponse {
  regionAverages: {
    time: number
    carbon: number
    cost: number
  }
}

interface AccountScoresVariationResponse {
  points: { user: number; startDate: string; endDate: string }[]
}

export const fetchAccountKCI = (id: string, queryParams: RequestParams): Promise<AccountKCIResponse> =>
  apiDashboardServer(endpoints.GET.KCI.route(id), { method: 'GET', queryParams })

export const fetchAccountRegionMetrics = (
  id: string,
  queryParams: RequestParams
): Promise<AccountRegionMetricsResponse> =>
  apiDashboardServer(endpoints.GET.RegionMetrics.route(id), { method: 'GET', queryParams })

export const fetchAccountScores = (id: string, queryParams: RequestParams): Promise<AccountScoresVariationResponse> =>
  apiDashboardServer(endpoints.GET.ScoreVariation.route(id), { method: 'GET', queryParams })

export const fetchAccountPaymentMethods = (
  accountId: string,
  queryParams: RequestParams
): Promise<GetResponse<PaymentMethod>> =>
  apiDashboardServer(endpoints.GET.PaymentMethods.route(accountId), {
    method: 'get',
    queryParams
  })

export interface PostCheckoutBody {
  startedAt: string
  endedAt?: string
  planId: string
  accountId: string
  quantity: number
}

export const postAccountPaymentMethods = (accountId: string, data: PostPaymentMethodBody): Promise<PaymentMethod> =>
  apiDashboardServer(endpoints.POST.AccountPaymentMethods.route(accountId), {
    method: 'post',
    data
  })

export const fetchUserAccountVehicles = (
  accountId: string,
  queryParams: RequestParams
): Promise<GetResponse<FetchAccountVehicleResponse>> =>
  apiDashboardServer(endpoints.GET.UserAccountVehicles.route(accountId), {
    method: 'get',
    queryParams: {
      relations: accountVehicleDefaultRelations,
      fields: [
        'color',
        'licensePlate',
        'licensePlateState',
        'vehicleId',
        'accountId',
        ...accountVehicleDefaultFields.map((field) => `vehicle.${field}`)
      ],
      ...queryParams
    }
  })
