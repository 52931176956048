import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { AxiosError, AxiosHeaders } from 'axios'
import { helpEmail } from 'constants/global'
import { REF_KEYS_LIST } from 'constants/onboarding'
import copy from 'copy-to-clipboard'
import React from 'react'
// Not ideal but this component is imported in our api/index setup and that file is included in
// our test API mocks. Importing from @commutifi-fe/ui doesn't do any tree shaking in the test
// environment so we need to import the components directly to avoid errors on components like Mapbox.
import { Button as AppButton, ButtonSize, ButtonType } from '@commutifi-fe/ui/components/AppButton'
import { Typography as AppTypography } from '@commutifi-fe/ui/components/AppTypography'
import { message } from '@commutifi-fe/ui/components/AntdApp'
import { Divider } from '@commutifi-fe/ui/components/Divider'
import { getLanguage } from 'shared/providers/LocaleProvider/_utils'
import storage from 'utils/storage'

const globalErrorMessages: Record<string, Record<string, string>> = {
  en: {
    'error.request.failed.retrying.title':
      'We detected one of our request failed. Thank you for you patience while we retry.',
    'error.request.failed.retrying.description': 'Retrying 3 times... Current attempt: ',
    'error.request.server.unreachable': 'We were not able to reach our servers. ',
    'error.request.network.offline':
      'It seems you are having network issues, if you need assistance, simply copy the error and send it to us.',
    'error.request.network.online': "Copy the error to you clipboard to send in the chat or simply click 'Send email'",
    'error.request.sendEmail.subject': 'Help Request for Network issue',
    'error.request.sendEmail.body.intro': 'Hi Commutifi, can you please help me with this error I got: ',
    'global.sendEmail': 'Send Email',
    'global.copy': 'Copy',
    'global.dismiss': 'Dismiss'
  },
  fr: {
    'error.request.failed.retrying.title':
      "Nous avons détecté que l'une de nos requêtes a échoué. Merci de votre patience pendant que nous réessayons.",
    'error.request.failed.retrying.description': 'Nous réessayons 3 fois... Tentative actuelle :',
    'error.request.server.unreachable': "Nous n'avons pas pu joindre nos serveurs.",
    'error.request.network.offline':
      "Il semble que vous ayez des problèmes de réseau, si vous avez besoin d'aide, copiez simplement l'erreur et envoyez-la nous.",
    'error.request.network.online':
      "Copiez l'erreur dans votre presse-papiers pour l'envoyer dans le chat ou cliquez simplement sur « Envoyer un email »",
    'error.request.sendEmail.subject': "Demande d'aide pour problème de réseau",
    'error.request.sendEmail.body.intro':
      "Salut Commutifi, pouvez-vous s'il vous plaît m'aider avec cette erreur que j'ai: ",
    'global.sendEmail': 'Envoyer un email',
    'global.copy': 'Copier',
    'global.dismiss': 'Fermer'
  }
}

const t = (key: string) => {
  const locale = storage.get('lang') || 'en-us'
  const lang: 'en' | 'fr' = getLanguage(locale)

  const messages = globalErrorMessages[lang] || globalErrorMessages.en
  return messages[key]
}

const errorButtonStyle = { padding: 0, height: 'auto' }
const errorButtonCommonProps = { type: 'link' as ButtonType, size: 'small' as ButtonSize }
export function RecurringError({ error }: { error: AxiosError }) {
  const errorMessage = React.useMemo(() => {
    const parsedData = JSON.parse(error.config?.data || '{}')
    error.config = {
      ...error.config,
      headers: error.config?.headers || new AxiosHeaders(),
      data: {
        ...parsedData,
        // Sanitize POST survey password. Each POST call or PUT call setup with
        // axios retry should sanitize private data here
        ...(parsedData.answers && {
          answers: (parsedData.answers || []).map((answer: any) => {
            if (answer.referenceKey === REF_KEYS_LIST.accountPassword) {
              return {
                ...answer,
                value: ''
              }
            }
            return undefined
          })
        })
      }
    }
    return `${t('error.request.sendEmail.body.intro')}${JSON.stringify(error.toJSON())}`
  }, [error])
  const handleDestroyMessage = () => {
    message.destroy(error.config?.url)
  }
  const handleCopyToClipboard = () => {
    copy(errorMessage)
  }

  return (
    <span style={{ display: 'inline-flex', flexDirection: 'column' }}>
      <AppTypography.Text className="u-margin-bottom--medium">
        {t('error.request.server.unreachable')}
        {!window.navigator.onLine ? t('error.request.network.offline') : t('error.request.network.online')}
      </AppTypography.Text>
      <span style={{ display: 'inline-flex', justifyContent: 'space-between' }}>
        <div>
          {!window.navigator.onLine ? null : (
            <>
              <AppButton
                {...errorButtonCommonProps}
                href={`mailto:${helpEmail}?subject=${encodeURIComponent(
                  t('error.request.sendEmail.subject')
                )}&body=${encodeURIComponent(errorMessage)}`}
                target="_blank"
                className="u-font-weight-semi"
                style={errorButtonStyle}
              >
                {t('global.sendEmail')}
              </AppButton>
              <Divider type="vertical" className="u-margin-horizontal--small" />
            </>
          )}
          <AppButton
            {...errorButtonCommonProps}
            onClick={handleCopyToClipboard}
            className="u-font-weight-semi"
            style={errorButtonStyle}
          >
            {t('global.copy')}
          </AppButton>
        </div>
        <AppButton
          {...errorButtonCommonProps}
          className="u-color--neutral-500"
          onClick={handleDestroyMessage}
          style={errorButtonStyle}
        >
          {t('global.dismiss')}
        </AppButton>
      </span>
    </span>
  )
}

export function RetryingError({ count }: { count: number }) {
  return (
    <span className="u-margin-left--small" style={{ display: 'inline-flex', flexDirection: 'column' }}>
      <AppTypography.Text strong="semi-bold">{t('error.request.failed.retrying.title')}</AppTypography.Text>
      <AppTypography.Text type="secondary">
        {t('error.request.failed.retrying.description')}
        {count}
      </AppTypography.Text>
    </span>
  )
}

export const displayErrorRetryLoading = (key: string, count: number) => {
  message.destroy()
  return message.loading({
    content: <RetryingError count={count} />,
    key,
    duration: 8
  })
}

export const displayFailedRetryError = (error: any) => {
  message.destroy()
  return message.error({
    key: error.config.url,
    content: <RecurringError error={error} />,
    duration: 0,
    icon: <FontAwesomeIcon icon={faExclamationCircle} className="u-margin-bottom--tiny" size="lg" />
  })
}

export const destroyMessage = (key: string) => message.destroy(key)
