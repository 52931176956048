import { EnterpriseGroupPlanProps } from '@commutifi/models/EnterpriseGroupPlan'
import { qs } from '@commutifi-fe/utils'
import type { GetResponse, RequestParams } from 'shared/interfaces/Api'
import { JSON_HEADER, apiCall, dashboardApiServer } from '../../index'
import { captureIsPlanValid } from '../../utils/global'
import { BookingProps } from '../bookings'
import type { DetailedPlan, PeriodPriceBreakdownProps, PriceCheckoutQueryParams } from './types'
import endpoints from './endpoints'

export {
  Countries,
  PlanBillingCancellationPolicy,
  PlanBillingFirstMonthPolicy,
  PlanBillingMethod,
  PlanBookingFieldCategory,
  PlanBookingFieldField,
  PlanBookingType,
  PlanCostType,
  PlanCurrency,
  PlanFrequency,
  ServiceCategory,
  ServiceMode,
  ServiceRegionDefinition,
  ServiceType
} from './types'

export type {
  AnyPlan,
  DetailedPlan,
  ListPlan,
  PeriodPriceBreakdownProps,
  Plan,
  PlanBookingField,
  PlanFaq,
  PlanFaqs,
  PlanPermissionProps,
  PriceCheckoutQueryParams
} from './types'

const apiDashboardServer = apiCall(dashboardApiServer)

export interface DetailedPlanQueryParams extends RequestParams {
  calculateInventory?: boolean
  accountId?: string
  startDate?: string
  endDate?: string
}

export const fetchOnePlan = async (planId: string, params: DetailedPlanQueryParams): Promise<DetailedPlan> => {
  const plan = await apiDashboardServer<Awaited<ReturnType<typeof fetchOnePlan>>>(endpoints.GET.Plan.route(planId), {
    method: 'get',
    params
  })

  captureIsPlanValid(plan, { endpoint: endpoints.GET.Plan.route(planId), params })
  return plan
}

export const fetchPlanCheckout = (
  planId: string,
  queryParams: PriceCheckoutQueryParams & { disableSubsidy?: boolean }
): Promise<GetResponse<PeriodPriceBreakdownProps>> =>
  apiDashboardServer(endpoints.GET.PlanCheckout.route(planId), {
    method: 'get',
    params: queryParams
  })

export interface PlanBookingQueryParams extends RequestParams {
  accountId?: string
  enterpriseId?: string
  startDate?: string
  endDate?: string
}
export const fetchPlanBookings = (
  planId: string,
  queryParams: PlanBookingQueryParams
): Promise<GetResponse<BookingProps>> =>
  apiDashboardServer(endpoints.GET.PlanBookings.route(planId), {
    method: 'get',
    queryParams
  })

export enum PlanGroupOperation {
  Create = 'create',
  Delete = 'delete'
}
export type PostPlanGroupBody = {
  groupId: string
  operation?: `${PlanGroupOperation}`
}[]
export const postPlanGroups = (planId: string, data: PostPlanGroupBody): Promise<EnterpriseGroupPlanProps[]> =>
  apiDashboardServer(endpoints.POST.PlanGroups.route(planId), {
    method: 'POST',
    data
  })

/**
 * LEGACY
 */
export const fetchPlansLegacy = (ids: string[]) =>
  apiDashboardServer(`/_/plans?${qs.stringify({ ids }, { indices: false })}`, {
    method: 'get',
    headers: JSON_HEADER
  })

export const fetchEntityPlans = (entityId: string, queryParams: Record<string, any>) =>
  apiDashboardServer(`/_/organizations/${entityId}/plans`, {
    method: 'GET',
    queryParams
  })

export const sendServiceSms = (id: string, body: { phoneNumber: string }) =>
  apiDashboardServer(`/services/${id}/sms`, {
    method: 'post',
    headers: JSON_HEADER,
    data: JSON.stringify(body)
  })
