import { CommutifiIconDefinition } from '../types'

export const faLongArrowLeft: CommutifiIconDefinition = {
  prefix: 'far',
  iconName: 'long-arrow-left',
  icon: [
    512,
    512,
    [],
    '56',
    'M 211.265625 36.480469 C 209.742188 36.949219 207.632812 37.941406 206.578125 38.820312 C 205.527344 39.699219 160.847656 84.027344 107.332031 137.425781 C 13.761719 230.820312 9.953125 234.6875 8.082031 238.609375 L 6.148438 242.707031 L 6.148438 266.714844 L 8.023438 270.8125 C 9.894531 274.796875 13 277.957031 108.148438 372.816406 C 179.703125 444.136719 207.105469 471.128906 209.039062 472.066406 C 212.320312 473.707031 218.699219 473.824219 222.097656 472.300781 C 223.503906 471.714844 229.59375 466.210938 237.496094 458.597656 C 252.546875 444.078125 253.773438 442.378906 253.773438 435.703125 C 253.773438 433.070312 253.363281 430.960938 252.546875 429.144531 C 251.609375 426.980469 237.085938 412.222656 181.226562 356.539062 L 111.136719 286.625 L 302.785156 286.332031 L 494.492188 286.039062 L 497.59375 284.339844 C 501.167969 282.347656 503.800781 279.363281 505.03125 275.792969 C 506.320312 272.160156 506.320312 237.261719 505.03125 233.632812 C 503.800781 230.058594 501.167969 227.074219 497.59375 225.082031 L 494.492188 223.386719 L 302.785156 223.09375 L 111.136719 222.800781 L 181.226562 152.886719 C 237.320312 96.964844 251.550781 82.503906 252.546875 80.277344 C 254.183594 76.589844 254.125 70.910156 252.429688 67.335938 C 250.730469 63.707031 225.550781 38.585938 222.273438 37.242188 C 219.050781 35.894531 214.484375 35.601562 211.265625 36.480469 Z M 211.265625 36.480469 '
  ]
}
