import { merge } from 'lodash'
import { useQuery, useMutation } from '@tanstack/react-query'
import { GetResponse } from '@commutifi-fe/interfaces'
import { IApiClient } from '../../Domain/Repositories/Http'
import {
  CommuterGuideRepository,
  CommuterGuideResourcesRepository,
  GetCommuterGuideRequest,
  GetCommuterGuideResourcesRequest
} from '../../Domain/Repositories/CommuterGuide'
import { CommuterGuide, Resource } from '../../Domain/Models/CommuterGuide'

export function CommuterGuideRepositoryImpl<TQueryParams extends Record<string, any>>(
  url: string,
  httpClient: IApiClient
) {
  return (request?: GetCommuterGuideRequest<TQueryParams>): CommuterGuideRepository<TQueryParams> => ({
    useCommuterGuide: (req) => {
      const { queryParams, queryOptions } = merge({}, req, request)
      return useQuery({
        queryKey: ['commuter-guides', queryParams],
        queryFn: () => httpClient.get<GetResponse<CommuterGuide>>(url, { params: queryParams }),
        select: (data) => data?.records[0],
        ...queryOptions
      })
    }
  })
}

export function CommuterGuideResourcesRepositoryImpl<TQueryParams extends Record<string, any>>(
  uris: { getResources: string; postResourceSms: ((id: string) => string) | undefined },
  httpClient: IApiClient
) {
  return (
    request?: GetCommuterGuideResourcesRequest<TQueryParams>
  ): CommuterGuideResourcesRepository<TQueryParams> => ({
    useGuideResources: (req) => {
      const { queryParams, queryOptions } = merge({}, req, request)
      return useQuery({
        queryKey: ['resources', queryParams],
        queryFn: () => httpClient.get<GetResponse<Resource>>(uris.getResources, { params: queryParams }),
        select: (data) => data?.records,
        ...queryOptions
      })
    },
    ...(!uris.postResourceSms
      ? {
          useSendResourceSMS: () => ({
            isPending: false,
            error: null,
            isError: false,
            mutateAsync: async () => Promise.resolve(undefined)
          })
        }
      : {
          useSendResourceSMS: (req) => {
            const { queryOptions } = req || {}
            return useMutation({
              mutationFn: ({ id, ...body }) =>
                httpClient.post(uris.postResourceSms?.(id) || '', { data: JSON.stringify(body) }),
              ...queryOptions
            })
          }
        })
  })
}
